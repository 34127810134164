import cn from 'classnames';
import { ReactNode, SVGProps, useState } from 'react';
const TailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='13'
    height='5'
    viewBox='0 0 13 5'
    fill='none'
    {...props}
  >
    <path
      d='M0.5 0H12.5L7.78037 3.93303C7.03868 4.5511 5.96132 4.5511 5.21963 3.93303L0.5 0Z'
      fill='black'
    />
  </svg>
);

export const Tooltip = ({
  children,
  text,
  isSmaller,
  isOpen,
  className,
  tooltipClassName,
}: {
  isOpen?: boolean;
  isSmaller?: boolean;
  className?: string;
  tooltipClassName?: string;
  children: ReactNode;
  text: string;
}) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={cn('w-fit cursor-pointer', className)}
      onMouseEnter={() => isOpen === undefined && setShow(true)}
      onMouseLeave={() => isOpen === undefined && setShow(false)}
    >
      <div
        className={cn(
          `absolute bottom-[30px] left-1/2 z-[98] flex -translate-x-1/2 flex-col items-center justify-center whitespace-pre break-words text-[20px] leading-[30px] text-white transition-all duration-150 ${
            show || isOpen ? 'visible' : 'hidden'
          }`,
          tooltipClassName
        )}
      >
        <div
          className={cn(
            'rounded-[8px] bg-black px-[12px] pb-[10px] pt-[8px]',
            isSmaller && 'rounded-[8px] bg-black px-[8px] pb-[3px] pt-[4px]'
          )}
        >
          {text}
        </div>
        <TailIcon />
      </div>

      {children}
    </div>
  );
};
