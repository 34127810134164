import './Modal.css';
import { animationTime } from '@/shared/lib/animationTime';
import { useOutsideClick } from '@/shared/lib/hooks/useOutsideClick';
import sx from 'classnames';
import { FC, ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Cross from 'src/assets/svg/cross';
interface Props {
  opened: boolean;
  children: ReactNode;
  scrollable?: boolean;
  withNoAnimate?: boolean;
  className?: string;
  onClose: (...args: any[]) => void;
}

const Modal: FC<Props> = ({
  opened,
  children,
  withNoAnimate = false,
  onClose,
  className,
  scrollable = false,
}) => {
  const [exitScreen, setExitScreen] = useState(false);

  useEffect(() => {
    if (opened) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [opened]);
  const closeHandler = async () => {
    setExitScreen(true);
    await animationTime();
    onClose();
    setExitScreen(false);
  };
  const ref = useOutsideClick(closeHandler);
  return (
    <>
      {opened &&
        createPortal(
          <div
            id='modal'
            className={sx(
              'fixed bottom-0 top-0 z-999999999999999 flex w-full justify-center overflow-y-auto bg-black/40',
              scrollable ? 'items-start py-[32px]' : 'items-center',
              exitScreen && !withNoAnimate
                ? 'animate-destroy-screen'
                : 'animate-load-screen'
            )}
          >
            <div
              className={sx(
                'relative flex-col items-center justify-center  gap-[32px] rounded-[32px] bg-white px-[56px] pb-[48px] pt-[56px]',
                className
              )}
              ref={ref}
            >
              {children}
              <div
                className={sx(
                  'absolute right-4 top-4 cursor-pointer rounded-xl',
                  'svg'
                )}
                data-testid={'button-close_modal'}
                onClick={closeHandler}
              >
                <Cross />
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default Modal;
