import BaseScreen from 'src/widgets/BaseScreen';

import { TopBarActiveUsers } from './components/TopBarActiveUsers';

const ActiveUsers = () => {
  return (
    <BaseScreen isSmallPT bg='white'>
      <TopBarActiveUsers />
    </BaseScreen>
  );
};
export default ActiveUsers;
