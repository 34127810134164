import { routes } from '@/shared/consts/consts';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { actions } from '../model/slice';
import { fetchForEdit, fetchStatic, updateOrCreate } from '../model/thunk';

export const useEdit = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { activeTab, form: formState } = useAppSelector((state) => state.foods);
  const handleChangeTab = (str: string) => {
    dispatch(actions.changeActiveTab(str));
  };

  useEffect(() => {
    dispatch(actions.checkCanSubmitForm());
  }, [dispatch, formState]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchStatic());
        if (id) {
          await dispatch(fetchForEdit(id));
        }
      } catch (e) {
        dispatch(
          setNotification({
            value: 'Ошибка при получении продукта',
            variant: 'error',
          })
        );
        navigate('/statistics');
      }
    })();
    return () => {
      dispatch(actions.resetForm());
    };
  }, [id]);
  const handleUpdate = async () => {
    try {
      await dispatch(updateOrCreate({ isEditing: true, id: id })).unwrap();
      dispatch(
        setNotification({ value: 'Продукт обновлен', variant: 'success' })
      );
      navigate('/' + routes.foods.foods);
    } catch (e) {
      dispatch(
        setNotification({
          value: `Ошибка при сохранении: ${e.response.status}`,
          variant: 'error',
        })
      );
    }
  };

  return {
    handleUpdate,
    activeTab,
    handleChangeTab,
    formState,
    id,
  };
};
