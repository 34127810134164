import { editBanner } from '@/entities/banners/model/bannerSlice';
import { IBanner } from '@/entities/banners/types/IBanner';
import { UISelect } from '@/pages/Banners/components/UISelect';
import { setState } from '@/shared/interfaces/common/setState';
import { toDateInputValue } from '@/shared/lib/toDateInputValue';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { ShowUntil } from '@/shared/ui/ShowUntil/ShowUntil';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  modalVisible: boolean;
  setModalVisible: setState<boolean>;
  banner: IBanner;
}

interface Form {
  title: string;
  height: number;
  weight: number;
  image?: FileList;
  placeId: number;
  showUntil?: string;
  link?: string;
  articleId?: number;
}

export const EditBannerModal: FC<Props> = ({
  modalVisible,
  setModalVisible,
  banner,
}) => {
  const selectPlaces = useAppSelector((state) => state.banners.selectPlaces);
  const selectArticles = useAppSelector(
    (state) => state.articles.selectArticles
  );
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register, formState, control } = useForm<Form>({
    mode: 'all',
    values: {
      title: banner.title,
      height: banner.height,
      weight: banner.weight,
      placeId: banner.place.id,
      showUntil: toDateInputValue(new Date(banner?.showUntil)),
      articleId: banner?.articleId,
      link: banner?.link,
    },
  });
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const onSubmit = handleSubmit(async (data) => {
    if (!data.placeId) {
      return enqueueSnackbar('Выберите место в приложении', {
        variant: 'warning',
      });
    }
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('height', data.height + '');
    formData.append('weight', data.weight + '');
    formData.append('image', data.image[0] || banner.image);
    formData.append('placeId', data.placeId + '');
    formData.append('showUntil', data.showUntil.toString());
    formData.append('link', data.link);
    formData.append('articleId', data.articleId + '');
    setLoading(true);
    await dispatch(editBanner({ id: banner.id, formData }));
    setLoading(false);
    setModalVisible(false);
  });
  return (
    <Dialog
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      fullWidth
      scroll='body'
    >
      <DialogTitle>Редактировать баннер</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          onSubmit={onSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, py: 1 }}
        >
          <TextField
            data-testid={'input-title'}
            label='Название баннера'
            {...register('title', {
              required: 'Введите название',
            })}
            error={errors?.title !== undefined}
            helperText={errors?.title?.message || ''}
          />
          <TextField
            data-testid={'input-height'}
            label='Высота баннера'
            type='number'
            {...register('height', { required: 'Введите высоту' })}
            error={errors?.height !== undefined}
            helperText={errors?.height?.message || ''}
          />
          <TextField
            data-testid={'input-weight'}
            label='Вес баннера'
            type='number'
            {...register('weight', { required: 'Введите вес' })}
            error={errors?.height !== undefined}
            helperText={errors?.height?.message || ''}
          />
          <Controller
            render={({ field }) => (
              <UISelect
                data-testid={'select-place'}
                label={'Место в приложении'}
                items={selectPlaces}
                {...field}
              />
            )}
            name={'placeId'}
            control={control}
          />
          <Controller
            render={({ field }) => (
              <UISelect
                data-testid={'select-article'}
                label={'Cтатья'}
                items={selectArticles}
                {...field}
              />
            )}
            name={'articleId'}
            control={control}
          />
          <ShowUntil
            label={'Показывать до'}
            registerProps={register('showUntil')}
          />
          <TextField
            {...register('link')}
            data-testid={'input-source'}
            label='Ссылка на источник'
            type='url'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 3 }} />}
        <Button
          type='submit'
          variant='contained'
          onClick={onSubmit}
          data-testid={'button-edit'}
          disabled={loading}
        >
          Редактировать
        </Button>
        <Button
          color='warning'
          variant='outlined'
          data-testid={'button-cancel'}
          onClick={() => setModalVisible(false)}
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
