import { promocodesActions } from '@/entities/promocodes/model/promocodesSlice';
import { useIsScrolled } from '@/shared/lib/hooks/useIsScrolled';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import classNames from 'classnames';
import SortUp from 'src/assets/svg/sortUp';

type Props = {
  basicGrid: string;
};

export const PromocodeBar = ({ basicGrid }: Props) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.promocodes.filters);
  const isScrolled = useIsScrolled(150);
  return (
    <div
      className={classNames(
        `sticky top-[12%] z-10 mb-[4px] mt-6 flex h-14 
  w-[1280px] items-start justify-start gap-8
  self-stretch rounded-[16px] border-[1px] border-[#F3F3F3] bg-[#FFF] px-6 py-4 text-lg font-normal not-italic leading-6 text-[color:var(--Simple-Gray-Midnight,#7D7D7D)] transition-all duration-75`,
        basicGrid,
        isScrolled && 'shadow-[0px_8px_20px_0px_rgba(0,0,0,0.08)]'
      )}
    >
      <div className='col-span-1 w-[164px]'>Промокод</div>
      <div className='col-span-1 w-[262px]'>Материал</div>
      <div className='col-span-1 w-[263px]'>Доступ</div>
      <div className='col-span-1 w-[64px]'>Скидка</div>
      <div
        onClick={() => {
          dispatch(promocodesActions.changeFilter({ key: 'usageCounter' }));
        }}
        className='col-span-1 flex w-[116px] cursor-pointer items-center gap-2'
      >
        Применен <SortUp fill={filters.usageCounter === null} />
      </div>
      <div
        onClick={() => {
          dispatch(promocodesActions.changeFilter({ key: 'endDate' }));
        }}
        className='col-span-1 flex max-w-[126px] cursor-pointer items-center gap-2'
      >
        Активность <SortUp fill={filters.endDate === null} />
      </div>
    </div>
  );
};
