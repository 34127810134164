import React from 'react';

interface ModerationStatusProps {
  isModerated: boolean;
}

const ModerationStatus: React.FC<ModerationStatusProps> = ({ isModerated }) => {
  return (
    <div
      className={`mb-[16px] flex h-[32px] w-[122px] items-start justify-center gap-2.5 rounded-[8px] px-[12px] pb-[7px] pt-[5px] ${
        isModerated ? 'bg-[#EDF7D1]' : 'bg-[#FFEFE2]'
      }`}
    >
      <p
        className={`text-sm font-normal not-italic leading-6 ${
          isModerated ? 'text-[#1BC47D]' : 'text-[#FF9800]'
        }`}
      >
        {isModerated ? 'Опубликовано' : 'На модерации'}
      </p>
    </div>
  );
};

export default ModerationStatus;
