const ErrorNotif = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Medium/Error'>
        <path
          id='Subtract'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 22.0002C17.5228 22.0002 22 17.5231 22 12.0002C22 6.4774 17.5228 2.00024 12 2.00024C6.47715 2.00024 2 6.4774 2 12.0002C2 17.5231 6.47715 22.0002 12 22.0002ZM9.70711 8.29314C9.31658 7.90261 8.68342 7.90261 8.29289 8.29314C7.90237 8.68366 7.90237 9.31683 8.29289 9.70735L10.5858 12.0002L8.29289 14.2931C7.90237 14.6837 7.90237 15.3168 8.29289 15.7074C8.68342 16.0979 9.31658 16.0979 9.70711 15.7074L12 13.4145L14.2929 15.7074C14.6834 16.0979 15.3166 16.0979 15.7071 15.7074C16.0976 15.3168 16.0976 14.6837 15.7071 14.2931L13.4142 12.0002L15.7071 9.70735C16.0976 9.31683 16.0976 8.68366 15.7071 8.29314C15.3166 7.90261 14.6834 7.90261 14.2929 8.29314L12 10.586L9.70711 8.29314Z'
          fill='#EC3333'
        />
      </g>
    </svg>
  );
};

export default ErrorNotif;
