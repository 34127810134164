import {
  IFilter,
  marketplaceActions,
} from '@/entities/marketplace/model/marketplaceSlice';
import Search from '@/pages/Review/components/SearchInput';
import useFilterVisibility from '@/shared/lib/hooks/useFilterVisibility';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { FilterSelect } from '@/shared/ui/Filters';
import Status from '@/shared/ui/Filters/Status';
import SortSelect from '@/shared/ui/Sort/SortSelect';
import { SortValues } from '@/shared/ui/Sort/SortValues';

const filterValues = [
  {
    label: 'Все',
    value: null,
    param: true,
  },
  {
    label: 'Опубликовано',
    value: 3,
    param: false,
  },
  {
    label: 'Скрыто',
    value: 2,
    param: false,
  },
  {
    label: 'Черновик',
    value: 1,
    param: false,
  },
];
const defaultSortValues = [
  {
    label: 'По умолчанию',
    value: null,
  },
  {
    label: 'По дате',
    value: 'DESC',
  },
];
export const Areas = ({
  type,
  className,
  sortValues = defaultSortValues,
  sortKey = 'createdAt',
}: {
  sortValues?: typeof defaultSortValues;
  sortKey?: keyof IFilter;
  type: 'courseFilters' | 'companyFilters';
  className?: string;
}) => {
  const {
    isFilterVisible,
    filterSelectOptions,
    handleMouseEnter,
    handleMouseLeave,
  } = useFilterVisibility();

  const {
    isFilterVisible: isSortVisible,
    filterSelectOptions: isFilterSelectOptions,
    handleMouseEnter: handleOptionsMouseEnter,
    handleMouseLeave: handleOptionsMouseLeave,
  } = useFilterVisibility();
  const value = useAppSelector((state) => state.articles.searchValue);
  const state = useAppSelector((state) => state.marketplace);
  const dispatch = useAppDispatch();

  const handleAddFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    v: { label: string; value: number; param: boolean }
  ) => {
    dispatch(
      marketplaceActions.changeFilters({ type, key: 'status', value: v.value })
    );
    handleMouseLeave();
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(
      marketplaceActions.changeFilters({
        type,
        key: 'search',
        value: e.target.value,
      })
    );

  const onClear = () =>
    dispatch(
      marketplaceActions.changeFilters({ type, key: 'search', value: '' })
    );
  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    { value }: { label: string; value: number | string }
  ) => {
    dispatch(
      marketplaceActions.changeFilters({ type, key: sortKey, value: value })
    );
    handleOptionsMouseLeave();
  };
  return (
    <div className={`flex-col gap-1 ${className}`}>
      <div className='flex w-[100%] gap-1'>
        <div
          className='relative flex-col'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Status
            ref={filterSelectOptions}
            currentFilter={
              filterValues?.find((v) => v.value === state?.[type].status).value
                ? filterValues?.find((v) => v.value === state?.[type].status)
                    .label
                : null
            }
            onClick={() => {}}
          />
          {isFilterVisible && (
            <FilterSelect
              ref={filterSelectOptions}
              data={filterValues}
              handleAddFilter={handleAddFilter}
            />
          )}
        </div>
        <div className='mr-[24px] flex-1'>
          <Search
            data-testid={'input-search'}
            onChange={onChange}
            value={state?.[type].search}
            isReview
            isLetter={value.length > 0}
            onClear={onClear}
          />
        </div>
        <div
          className='relative z-[3] flex select-none flex-col self-center'
          onMouseEnter={handleOptionsMouseEnter}
          onMouseLeave={handleOptionsMouseLeave}
        >
          <SortSelect
            onClick={() => {}}
            ref={isFilterSelectOptions}
            isSortVisible={isSortVisible}
            activeSort={sortValues.find(
              (v) => v.value === state[type].createdAt
            )}
          />
          {isSortVisible && (
            <SortValues
              data={sortValues}
              ref={isFilterSelectOptions}
              handleClick={handleClick}
              activeSort={sortValues.find(
                (v) => v.value === state[type].createdAt
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
