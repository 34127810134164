import { useAppSelector } from '@/shared/model';

interface Props {
  data: FilterSelectType[];
  ref?: any;
  handleAddFilter: (event: React.MouseEvent<HTMLDivElement>, v: any) => void;
}

export type FilterSelectType = {
  value: string | number;
  label: string;
} & unknown;

export const FilterSelect = ({ data, handleAddFilter, ref }: Props) => {
  const selected = useAppSelector((state) => state.statistics.activeReqSource);
  const isSelected = (item: FilterSelectType) =>
    selected.some((s) => item.value === s.value);

  return (
    <div
      ref={ref}
      className='absolute top-[100%] z-[999999999999999] mt-[4px] flex h-min max-h-[40vh] w-min flex-col items-start justify-start gap-1 overflow-auto rounded-2xl bg-white p-1 shadow-lg'
    >
      {data.map((item: any) => (
        <div
          onClick={(event) => handleAddFilter(event, item)}
          key={item.label}
          className={`flex cursor-pointer items-center self-stretch rounded-2xl px-[14px] py-[12px] text-base font-normal not-italic leading-5 hover:bg-gray200 ${
            isSelected(item) && 'bg-gray100'
          }`}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};
