import { IArticle, IAuthor } from '@/entities/articles/types/IArticle';
import { ISelectItem } from '@/shared/interfaces/common/ISelectItem';
import { Change } from '@/shared/interfaces/types';
import { swapArr } from '@/shared/lib/spawArr';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ArticleTabVariants, IFormat, SingleArticle } from '../types/IArticle';
import {
  addAuthor,
  changePublicationType,
  createSingleArticle,
  deleteArticle,
  editAuthor,
  editSingleArticle,
  fetchAllArticles,
  fetchFormats,
  fetchSingleArticle,
  fetchTopics,
  getArticleSources,
} from './articleThunks';

export interface IArticleSource {
  title: string;
  order: number;
  articleId?: number;
  link: string;
}

const initSource: IArticleSource = { title: '', link: '', order: 1 };

export type ChangeSource = Change<IArticleSource, string>;

interface IArticleSlice {
  articles: IArticle[];
  articlesLoading: boolean;
  selectArticles?: ISelectItem[];
  activeTab: ArticleTabVariants;
  formDirty?: boolean;
  searchValue: string;
  isInit?: {
    author?: boolean;
    image?: boolean;
  };
  createdAt: 'ASC' | 'DESC' | null;
  filterValues: Array<{
    label: string;
    value: number;
    param: boolean;
  }>;
  sort?: { label: string; value: number | string };
  filter: { label: string; value: number; param: boolean } | null;
  sourcesAmountInit: number;
  sourcesInit: IArticleSource[];
  topicOptions: IFormat[];
  topicsInitIds: {
    ids: number[];
  };
  sortTypeInit: Array<{ label: string; value: number | string }>;
  formatOptions: IFormat[];
  articlesForm: {
    isLoading?: boolean;
    main: SingleArticle;
    author: IAuthor;
    source: IArticleSource[];
  };
}

const initialState: IArticleSlice = {
  articles: [],
  isInit: {
    author: false,
    image: false,
  },
  formDirty: false,
  articlesLoading: false,
  sourcesAmountInit: 0,
  searchValue: '',
  topicOptions: [],
  topicsInitIds: {
    ids: [],
  },
  formatOptions: [],
  sort: { label: 'По дате', value: 'DESC' },
  sourcesInit: [],
  createdAt: null,
  filter: null,
  filterValues: [
    {
      label: 'Все',
      value: null,
      param: true,
    },
    {
      label: 'Опубликовано',
      value: 3,
      param: false,
    },
    {
      label: 'Скрыто',
      value: 2,
      param: false,
    },
    {
      label: 'Черновик',
      value: 1,
      param: false,
    },
  ],
  sortTypeInit: [
    {
      label: 'По дате',
      value: 'DESC',
    },
    {
      label: 'По дате (сначала старые)',
      value: 'ASC',
    },
  ],
  activeTab: 'main',
  articlesForm: {
    isLoading: false,
    main: { topics: [] },
    author: {},
    source: [initSource],
  },
} as IArticleSlice;

const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    resetForm: (state) => {
      state.articlesForm = {
        main: {},
        author: null,
        source: [initSource],
      };
      state.formDirty = false;
    },
    changeSearch(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    changeActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    addFilter(
      state,
      action: PayloadAction<{ label: string; value: number; param: boolean }>
    ) {
      state.filter = action.payload;
    },
    swapSpeakers(
      state,
      action: PayloadAction<{ destIndex: number; index: number }>
    ) {
      const { index, destIndex } = action.payload;
      state.formDirty = true;
      swapArr(index, destIndex, state.articlesForm.source);
    },
    changeSort: (
      state,
      action: PayloadAction<{ label: string; value: number | string }>
    ) => {
      state.sort = action.payload;
      // @ts-ignore
      state.createdAd = action.payload.value;
    },
    deleteArticleSource(state, action: PayloadAction<number>) {
      state.formDirty = true;
      state.articlesForm.source = state.articlesForm.source.filter(
        (elem) => elem.order !== action.payload
      );
    },
    onSwapArticleSources(
      state,
      action: PayloadAction<{ index: number; destIndex: number }>
    ) {
      const { index, destIndex } = action.payload;
      state.formDirty = true;
      swapArr(index, destIndex, state.articlesForm.source);
    },
    changeArticleSources(state, action: PayloadAction<ChangeSource>) {
      const { index, key, value } = action.payload;
      //@ts-expect-error
      state.articlesForm.source[index][key] = value;
      state.formDirty = true;
    },
    setCoverFileInitDirty: (state) => {
      state.isInit.image = false;
      state.formDirty = true;
    },
    setFormDirty: (state, action: PayloadAction<boolean>) => {
      state.formDirty = action.payload;
    },
    setAuthorFileInitDirty: (state) => {
      state.isInit.author = false;
      state.formDirty = true;
    },
    setCurrentFormat: (state, action) => {
      state.articlesForm.main.format = action.payload;
    },
    setCurrentTopics: (state, action) => {
      state.articlesForm.main.topics = action.payload;
    },
    addArticleSource(state) {
      const newArticle = {
        ...initSource,
        order: state.articlesForm.source?.length + 1,
      };
      state.formDirty = true;
      state.articlesForm.source.push(newArticle);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllArticles.fulfilled, (state, action) => {
      state.articles = action.payload;
      state.articlesLoading = false;
    });
    builder.addCase(fetchAllArticles.pending, (state) => {
      state.articlesLoading = !state.articles?.length;
    });
    builder.addCase(fetchAllArticles.rejected, (state, action) => {
      console.error(`ERROR IN fetchAllArticles >>> ${action.payload}`);
      state.articlesLoading = false;
    });
    builder.addCase(changePublicationType.fulfilled, (state, action) => {
      const id = action.payload;
      const publStatus =
        state.articles?.find((a) => a.id === id)?.publicationStatus.id || 1;
      state.articles = state.articles.map((elem) =>
        elem.id === id
          ? {
              ...elem,
              publicationStatus: { id: publStatus === 3 ? 2 : 3, title: '' },
            }
          : elem
      );
    });
    builder.addCase(fetchTopics.fulfilled, (state, action) => {
      //@ts-ignore
      state.topicOptions = action.payload.topics;
    });
    builder.addCase(fetchFormats.fulfilled, (state, action) => {
      //@ts-ignore
      state.formatOptions = action.payload.formats;
    });
    builder.addCase(getArticleSources.fulfilled, (state, action) => {
      console.log(action.payload, '[getArticleSources]');
      state.sourcesAmountInit = action.payload.sources.length;
      if (action.payload.sources.length > 0) {
        state.articlesForm.source = action.payload.sources.map((item) => item);
      }
      state.sourcesInit = action.payload.sources.map((item) => item);
    });
    builder.addCase(createSingleArticle.fulfilled, (state, action) => {
      const { payload } = action;
      state.articlesForm.main.title = payload.title;
      state.articlesForm.main.createdAt = payload.createdAt;
      state.articlesForm.main.body = payload.body;
      state.articlesForm.main.image = payload.image;
      state.articlesForm.main.publishedAt = payload?.publishedAt;
      state.articlesForm.main.publicationStatus = payload.publicationStatus;
      state.articlesForm.main.topics = payload.topics;
      state.articlesForm.main.format = payload.format;
      state.isInit.image = true;
    });
    builder.addCase(fetchSingleArticle.fulfilled, (state, action) => {
      console.log(action.payload, '[FETCHSINGLEARTICLE]');
      const { payload } = action;
      state.articlesForm.main.title = payload?.title;
      state.articlesForm.main.createdAt = payload?.createdAt;
      state.articlesForm.main.publishedAt = payload?.publishedAt;
      state.articlesForm.main.body = payload?.body;
      state.articlesForm.main.image = payload?.image;
      state.articlesForm.main.superOrder = payload?.superOrder;
      state.articlesForm.main.publicationStatus = payload?.publicationStatus;

      if (payload.author === null) {
        state.articlesForm.author = {
          avatar: [],
          positionAtWork: '',
          name: '',
        };
      } else {
        state.articlesForm.author = {
          avatar: [payload.author?.avatar as unknown as string],
          positionAtWork: payload.author?.positionAtWork || '',
          name: payload.author?.name || '',
          id: payload.author.id,
        };
      }
      state.articlesForm.main.topics = payload.topics;
      state.articlesForm.main.format = payload.format;
      state.isInit.image = true;
      state.isInit.author = true;
      state.topicsInitIds.ids = action.payload.topics.map((item) => item.id);
      state.articlesForm.isLoading = false;
    });
    builder.addCase(fetchSingleArticle.rejected, (state, action) => {
      state.articlesForm.isLoading = false;
      console.error(`ERROR IN fetchSingleArticle >>> ${action.payload}`);
    });
    builder.addCase(fetchSingleArticle.pending, (state) => {
      state.articlesForm.isLoading = true;
    });
    builder.addCase(deleteArticle.fulfilled, (state, action) => {
      state.articles = state.articles.filter(
        (item) => item.id !== action.payload
      );
    });
    builder.addCase(editAuthor.fulfilled, (state, action) => {
      console.log(action.payload, '[AUTHOR EDIT]');
    });
    builder.addCase(addAuthor.fulfilled, (state, action) => {
      state.articlesForm.author.id = action.payload.id;
      state.articlesForm.author.avatar = [action.payload.avatar];
      state.articlesForm.author.name = action.payload.name;
    });
    builder.addCase(editSingleArticle.rejected, (_, action) => {
      console.error(`ERROR IN editSingleArticle >>> ${action.payload}`);
    });
  },
});

export const { actions: articlesActions, reducer: articleReducer } =
  articleSlice;
