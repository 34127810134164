import authService from '@/entities/auth/api/auth-service';
import { IRole } from '@/entities/auth/types/IAuth';
import {
  IBoughtProduct,
  IEmailUsers,
  IStatistics,
  IUser,
  unionTimePeriodType,
} from '@/entities/statistics/types/IStatistics';
import { tryCatch } from '@/shared/lib/tryCatch';
import { createAsyncThunk } from '@reduxjs/toolkit';

import statisticsService from '../api/statistics-service';

export const fetchAllUsers = createAsyncThunk(
  'statistics/fetchAllUsers',
  async (
    {
      lastActivityAt,
      search,
      regTypes,
      limit,
      page,
    }: {
      lastActivityAt?: 'ASC' | 'DESC';
      search?: string;
      limit?: number;
      page?: number;
      regTypes?: any;
    },
    { rejectWithValue }
  ) => {
    const [data, err] = await tryCatch<{ users: IUser[] }>(
      statisticsService.getAllUsers(
        {
          lastActivityAt,
          search,
          regTypes,
        },
        { limit, page }
      )
    );
    if (err) {
      throw rejectWithValue(`ERROR in fetchAllUsers >>> ${err.message}`);
    }
    return { data, page };
  }
);

export const fetchAllRoles = createAsyncThunk(
  'statistics/fetchAllRoles',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch<IRole[]>(authService.getAllRoles());
    if (err) {
      throw rejectWithValue(`ERROR in fetchAllRoles >>> ${err.message}`);
    }
    return data;
  }
);

export const fetchAllStatistics = createAsyncThunk(
  'statistics/fetchAllStatistics',
  async (period: unionTimePeriodType, { rejectWithValue }) => {
    const [data, err] = await tryCatch<{ statistics: IStatistics[] }>(
      statisticsService.getAllStatistics(period)
    );
    if (err) {
      throw rejectWithValue(`ERROR in fetchAllStatistics >>> ${err.message}`);
    }
    return data;
  }
);

export interface IOrderParam {
  filters?: any;
  search?: string;
  createdAt?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export const fetchAllBoughtProducts = createAsyncThunk(
  'statistics/fetchAllBoughtProducts',
  async (
    { filters, search, createdAt = 'ASC', limit, page }: IOrderParam,
    { rejectWithValue }
  ) => {
    const [data, err] = await tryCatch<{ orders: IBoughtProduct[] }>(
      statisticsService.getAllBoughtProducts(search, filters, createdAt, {
        limit,
        page,
      })
    );
    // console.log(data)
    if (err) {
      throw rejectWithValue(
        `ERROR in fetchAllBoughtProducts >>> ${err.message}`
      );
    }
    return { data, page };
  }
);

export const fetchAllEmailUsers = createAsyncThunk(
  'statistics/fetchAAllEmailUsers',
  async (
    {
      createdAt,
      limit,
      page,
    }: { createdAt: 'ASC' | 'DESC'; limit?: number; page?: number },
    { rejectWithValue }
  ) => {
    const [data, err] = await tryCatch<{ registrations: IEmailUsers[] }>(
      statisticsService.getAllEmailUsers(createdAt, { limit, page })
    );
    if (err) {
      throw rejectWithValue(
        `ERROR in fetchAllRegistrations >>> ${err.message}`
      );
    }
    return { data, page };
  }
);
