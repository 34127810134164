import { getTouchedElemsFromFirstArray } from '@/shared/lib/getTouchedElemsFromFirstArray';
import { getUniqueElemsFromFirstArray } from '@/shared/lib/getUniqueElemsFromFirstArray';
import { tryCatch, tryCatchAnyPromise } from '@/shared/lib/tryCatch';
import { uploadImg } from '@/shared/lib/uploadImg';
import { RootState } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../api';
import {
  IApiRule,
  IFoods,
  IFoodsFilter,
  IRecommendation,
  IRule,
} from '../types';

export const fetchAll = createAsyncThunk(
  'fetchAll',
  async (
    { filters }: { filters?: IFoodsFilter },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch<{
      foods: IFoods[];
    }>(api.getAll({ data: filters }));
    if (err) {
      dispatch(
        setNotification({
          value: 'Ошибка при получении продуктов',
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    return data.foods;
  }
);

export const fetchStatic = createAsyncThunk('fetchStatic', async () => {
  const [measures] = await tryCatch<any>(api.getMeasures());
  const [ages] = await tryCatch<any>(api.getAges());
  const [categories] = await tryCatch<any>(api.getCategories());

  return {
    measures: measures.measures,
    ages: ages.ages,
    categories: categories.categories,
  };
});

export const deleteSingle = createAsyncThunk(
  'deleteSingle',
  async (id: string) => {
    const [] = await tryCatch<any>(api.delete(id));

    return { id };
  }
);

export const fetchForEdit = createAsyncThunk(
  'fetchForEdit',
  async (id: string, { rejectWithValue, dispatch }) => {
    const [recipe, recipeErr] = await tryCatch<IFoods>(api.getSingle(id));
    const handleErr = (err: AxiosError, text?: string) => {
      dispatch(
        setNotification({
          value: text ?? 'Упс... Что-то пошло не так',
          variant: 'error',
        })
      );
      throw rejectWithValue(recipeErr.message);
    };
    if (recipeErr) {
      handleErr(recipeErr, 'Ошибка при получении продукта');
    }

    const [rules] = await tryCatch<any>(api.getRules(+id));
    const [recommendations] = await tryCatch<any>(api.getRecommendations(+id));

    return {
      foods: recipe,
      recommendations: recommendations.recommendations,
      rules: rules.servingRules,
    };
  }
);

export const updateOrCreate = createAsyncThunk(
  'updateOrCreate',
  async (
    { isEditing, id }: { isEditing?: boolean; id?: string },
    { rejectWithValue, dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const {
      foods: {
        form: { main, recs, rules },
      },
    } = state;
    const { form } = state.foods;

    const img = await uploadImg(main.image);

    const data = {
      name: main.title,
      description: main.description,
      image: img?.[0],
      categoryId: main.category.id,
      possibleAllergy: !!main.isMarked,
      backgroundColor: main.color.id,
      measureId: main.measure.id,
      fromWhatAgeId: main.age.id,
    };
    const handleErr = (err: AxiosError, text?: string) => {
      console.log(err);
      dispatch(
        setNotification({
          value: `${text ?? 'Упс... Что-то пошло не так'}`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.response.status);
    };
    const [foods, err] = await tryCatch<IFoods>(
      isEditing ? api.update(id, data) : api.create(data)
    );

    if (err) {
      handleErr(
        err,
        `Ошибка при ${isEditing ? 'редактировании' : 'создании'} продукта [${
          err.response.status
        }]`
      );
    }

    if (!isEditing) {
      return foods;
    }
    const promises: Promise<any>[] = [];

    {
      //rules
      const mapRules = async (rules: IRule[]): Promise<IApiRule[]> => {
        const res = rules?.map(async (rule) => {
          const image = await uploadImg(rule.img);
          return {
            image: image?.[0],
            name: rule.name,
            fromWhatAgeId: rule.ageId,
            id: rule.id,
            order: rule.order,
          };
        });
        return Promise.all(res);
      };
      const initRulesIds = form.initRules.map((elem) => elem.id);
      const allRules = [...rules]
        .filter((v) => v.name.trim() !== '')
        .map((v, index) => ({
          ...v,
          order: index,
        }));

      const deletedRules = form.initRules
        .filter((v) => {
          return !rules.map((q) => q.id).includes(v.id);
        })
        .map((v) => v.id);

      if (deletedRules?.length) {
        promises.push(api.deleteRules(deletedRules, +id));
        if (err) {
          dispatch(
            setNotification({
              value: `Ошибка при удалении правил [${err.response.status}]`,
              variant: 'error',
            })
          );
          throw rejectWithValue(err.message);
        }
      }
      const rulesToAdd = getUniqueElemsFromFirstArray(allRules, initRulesIds);

      if (rulesToAdd?.length) {
        const newRules = await mapRules(rulesToAdd);

        promises.push(api.addRules(newRules, +id));
        if (err) {
          dispatch(
            setNotification({
              value: `Ошибка при добавлении правил [${err.response.status}]`,
              variant: 'error',
            })
          );
          throw rejectWithValue(err.message);
        }
      }
      const rulesToEdit = getTouchedElemsFromFirstArray(
        allRules.map((elem) => ({
          value: elem,
        })),
        form.initRules,
        initRulesIds
      ).map((elem) => elem.value);
      if (rulesToEdit?.length) {
        promises.push(api.updateRules(await mapRules(rulesToEdit), +id));

        if (err) {
          dispatch(
            setNotification({
              value: `Ошибка при изменении правил [${err.response.status}]`,
              variant: 'error',
            })
          );
          throw rejectWithValue(err.message);
        }
      }
    }

    {
      //recs
      const mapRecs = (recs: IRecommendation[]) => {
        return recs?.map((rec) => {
          return {
            ...rec,
          };
        });
      };
      const initRecsIds = form.initRecommendations.map((elem) => elem.id);
      const allRecs = [...recs]
        .filter((v) => !!v.text.trim())
        .map((v, index) => ({
          text: v.text,
          id: v.id,
          order: index,
        }));

      const deletedRecs = form.initRecommendations
        .filter((v) => !recs.map((q) => q.id).includes(v.id))
        .map((v) => v.id);

      if (deletedRecs?.length) {
        promises.push(api.deleteRecommendations(deletedRecs, +id));
        if (err) {
          dispatch(
            setNotification({
              value: `Ошибка при удалении вопросов [${err.response.status}]`,
              variant: 'error',
            })
          );
          throw rejectWithValue(err.message);
        }
      }
      const recsToAdd = getUniqueElemsFromFirstArray(allRecs, initRecsIds);

      if (recsToAdd?.length) {
        promises.push(api.addRecommendations(mapRecs(recsToAdd), +id));
        if (err) {
          dispatch(
            setNotification({
              value: `Ошибка при добавлении рекоммендаций [${err.response.status}]`,
              variant: 'error',
            })
          );
          throw rejectWithValue(err.message);
        }
      }
      const recsToEdit = getTouchedElemsFromFirstArray(
        allRecs.map((elem) => ({
          value: elem,
        })),
        form.initRules,
        initRecsIds
      ).map((elem) => elem.value);

      if (recsToEdit?.length) {
        promises.push(api.updateRecommendations(mapRecs(recsToEdit), +id));

        if (err) {
          dispatch(
            setNotification({
              value: `Ошибка при изменении вопросов [${err.response.status}]`,
              variant: 'error',
            })
          );
          throw rejectWithValue(err.message);
        }
      }
    }

    const [res] = await tryCatchAnyPromise(Promise.all(promises));
    return { foods: res };
  }
);
