import { articlesActions } from '@/entities/articles/model/articleSlice';
import { PublicationStatus } from '@/entities/articles/types/IArticle';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import * as UI from '@/shared/ui';
import DropInput from '@/shared/ui/DropInput/DropInput';
import { Input } from '@/shared/ui/Input/Input';
import React, { memo } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { IsEditOrCreate } from '../index';

const Author = ({
  control,
  setValue,
  watch,
}: {
  control?: Control<FieldValues, any>;
  status?: PublicationStatus;
  isValid?: boolean;
  handleSubmit: any;
  watch?: any;
  setValue?: any;
  isCreatingOrEditing: IsEditOrCreate;
}) => {
  const dispatch = useAppDispatch();
  const { name, positionAtWork, avatar } = useAppSelector(
    (state) => state.articles.articlesForm.author
  );
  const onClear = (field: string) => {
    const inputElement = document.querySelector(
      `input[name=${field}]`
    ) as HTMLInputElement;
    setValue(field, '');
    dispatch(articlesActions.setFormDirty());
    inputElement.focus();
  };

  return (
    <UI.Block>
      <fieldset className='mb-[40px] flex w-[100%] items-center justify-between'>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Автор
        </h1>
      </fieldset>
      <div className='flex w-[100%] flex-col gap-[32px]'>
        <Controller
          name='avatar'
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange, value } }) => (
            <DropInput
              data-testid={'dropzone-avatar'}
              setFiles={async ([files]: any) => {
                console.log(avatar);
                dispatch(articlesActions.setFormDirty());
                onChange({ target: { value: [files[0]] } });
              }}
              onDelete={() => onClear('avatar')}
              files={value?.[0] ? value : []}
              label='avatar'
              sizes={''}
              withIcon
              htmlFor={'avatar'}
              format='dropzone'
              title={'Фото'}
              setValue={setValue}
              name='avatar'
              id='avatar'
            />
          )}
        />
        <div className='flex gap-[32px]'>
          <Controller
            name='name'
            defaultValue={name || ''}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                {...field}
                type='type'
                data-testid={'input-name'}
                name='name'
                isClose={watch('name')?.length > 0}
                onClear={() => onClear('name')}
                htmlFor={'name'}
                title={'Имя Фамилия'}
                className='w-[428px]'
              />
            )}
          />

          <Controller
            name='positionAtWork'
            defaultValue={positionAtWork || ''}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Input
                {...field}
                type='type'
                data-testid={'input-position'}
                htmlFor={'positionAtWork'}
                title={'Должность'}
                name='positionAtWork'
                isClose={watch('positionAtWork')?.length > 0}
                onClear={() => onClear('positionAtWork')}
                className='w-[428px]'
              />
            )}
          />
        </div>
      </div>
    </UI.Block>
  );
};

export default memo(Author);
