import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { actions } from '../model/slice';
import { fetchStatic, updateOrCreate } from '../model/thunk';
export const useCreate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeTab, form: formState } = useAppSelector((state) => state.foods);
  const handleChangeTab = (str: string) => {
    dispatch(actions.changeActiveTab(str));
  };

  useEffect(() => {
    dispatch(fetchStatic());
    return () => {
      dispatch(actions.resetForm());
    };
  }, []);
  useEffect(() => {
    dispatch(actions.checkCanSubmitForm());
  }, [dispatch, formState]);
  const handleCreate = async () => {
    try {
      const res = await dispatch(updateOrCreate({ isEditing: false }));
      const id = res?.payload?.id;
      if (id) {
        dispatch(
          setNotification({ value: 'Продукт добавлен', variant: 'success' })
        );
        navigate(('/foods/edit-foods/' + id) as string);
      }
    } catch (e) {
      dispatch(
        setNotification({
          value: `Ошибка при создании: ${e.response.status}`,
          variant: 'error',
        })
      );
    }
  };
  return {
    handleCreate,
    activeTab,
    handleChangeTab,
    formState,
  };
};
