import React from 'react';

const ChevronDown = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Small/Chevron Down'>
        <path
          id='Vector'
          d='M8.67435 11.3824L12.7179 7.30976C12.8073 7.22047 12.8782 7.11423 12.9267 6.99718C12.9751 6.88013 13 6.75459 13 6.62779C13 6.50099 12.9751 6.37544 12.9267 6.25839C12.8782 6.14134 12.8073 6.03511 12.7179 5.94582C12.5392 5.76692 12.2975 5.6665 12.0456 5.6665C11.7936 5.6665 11.5519 5.76692 11.3732 5.94582L7.99725 9.34607L4.62126 5.94582C4.44258 5.76692 4.20087 5.6665 3.94893 5.6665C3.69698 5.6665 3.45527 5.76692 3.27659 5.94582C3.1882 6.03557 3.11828 6.14201 3.07082 6.25904C3.02336 6.37606 2.9993 6.50138 3.00003 6.62779C2.9993 6.7542 3.02336 6.87951 3.07082 6.99654C3.11828 7.11357 3.1882 7.22001 3.27659 7.30976L7.32015 11.3824C7.4088 11.4724 7.51428 11.5439 7.63049 11.5926C7.7467 11.6414 7.87135 11.6665 7.99725 11.6665C8.12315 11.6665 8.2478 11.6414 8.36401 11.5926C8.48022 11.5439 8.5857 11.4724 8.67435 11.3824Z'
          fill='#ABABAB'
        />
      </g>
    </svg>
  );
};

export default ChevronDown;
