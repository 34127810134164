import { logout } from '@/entities/auth/model/authSlice';
import { setState } from '@/shared/interfaces/common/setState';
import { useOutsideClick } from '@/shared/lib/hooks/useOutsideClick';
import { useAppDispatch } from '@/shared/model';
import sx from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoutRed } from 'src/assets/svg/LogoutRed';

interface Props {
  open: boolean;
  setOpen: setState<boolean>;
}

const menuItems = [
  // {
  //   image: <Profile />,
  //   link: routes.adminRoutes.statistics + '/user',
  //   label: 'Пользователи',
  // },
  // {
  //   image: <MediumDelete />,
  //   link: routes.marketplace.deletedCompanies,
  //   label: 'Корзина',
  // },
  {
    image: <LogoutRed />,
    link: 'exit',
    label: 'Выйти',
  },
];

const Menu: FC<Props> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(logout());
    navigate('/login', { replace: true });
  };

  const handleLink = (
    event: React.MouseEvent<HTMLDivElement>,
    link: string
  ) => {
    if (link === 'exit') {
      handleClose();
    } else {
      navigate(link, { replace: true });
    }
  };

  const ref = useOutsideClick(() => setOpen(false), ['close-btn']);
  return (
    <div
      className={sx(
        'absolute right-0 top-[73px] z-50',
        open ? 'visible' : 'hidden'
      )}
      ref={ref}
    >
      <div className='flex w-[336px] flex-col justify-center gap-[4px] rounded-[32px] bg-white p-[16px] shadow-lg'>
        {menuItems.map(({ label, link, image }) => (
          <div
            key={label}
            data-testid={'menu-items-' + label}
            onClick={(e) => handleLink(e, link)}
            className='flex cursor-pointer items-center gap-[16px] self-stretch rounded-[16px] p-[16px] text-[20px] font-normal not-italic leading-5 hover:bg-[#EBF1FC]'
          >
            {image} {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Menu;
