import './SignUp.css';
import { resetError, signUp } from '@/entities/auth/model/authSlice';
import { routes } from '@/shared/consts/consts';
import openNotification from '@/shared/lib/notification';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { getCookie } from 'typescript-cookie';

import { SignUpTypes } from './SignUp-Types';
import SignUpView from './SignUp-View';

const SignUpContainer = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpTypes>({ mode: 'onChange' });

  if (getCookie('jwt')) {
    return <Navigate replace to='/login' />;
  }
  const onSubmit = async () => {
    const result = await dispatch(signUp({ email, password }));
    if (typeof result.payload === 'string') return;
    openNotification(
      'topRight',
      'success',
      'Введите email и код, который мы выслали на почту, указанную при регистрации'
    );
    navigate(`/${routes.authRoutes.code}`);
  };
  const handleLogin = () => {
    navigate(`/${routes.authRoutes.login}`);
  };

  const handleEmail = (event: any) => {
    setEmail(event.target.value);
    dispatch(resetError());
  };
  return (
    <SignUpView
      handleSubmit={handleSubmit}
      handleFormSubmit={onSubmit}
      register={register}
      handleEmail={handleEmail}
      setPassword={setPassword}
      errors={errors}
      handleLogin={handleLogin}
      emailHelperText={error}
    />
  );
};

export default SignUpContainer;
