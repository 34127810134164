import React from 'react';

const CheckCircle = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Medium/Check_circle'>
        <path
          id='Subtract'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.4808 9.66436C16.8477 9.25158 16.8105 8.61951 16.3977 8.25259C15.9849 7.88567 15.3529 7.92285 14.9859 8.33564L10.4137 13.4794L9.15928 12.0159C8.79986 11.5965 8.16856 11.548 7.74923 11.9074C7.32991 12.2668 7.28135 12.8981 7.64077 13.3175L9.39225 15.3609C9.91807 15.9743 10.8643 15.9828 11.4011 15.3789L16.4808 9.66436Z'
          fill='black'
        />
      </g>
    </svg>
  );
};

export default CheckCircle;
