/* eslint-disable @typescript-eslint/naming-convention */
import { IPromocode } from '@/entities/promocodes/types/IPromocodes';
import { FilterSelectType } from '@/shared/ui/Filters';

export interface IUser {
  id: number;
  phone: string;
  isPhoneNumberConfirmed: boolean;
  authType: string;
  email: string;
  lastActivityAt: string;
  updatedAt: string;
  createdAt: string;
  ip: string;
}
export interface IStatistics {
  type: string;
  amount: number;
  differenceForPreviousPeriod: number;
}
export interface IEmailUsers {
  id: number;
  email: string;
  authType: string;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
}
export interface IBoughtProduct {
  id: string;
  email: string;
  phone: string;
  name: string;
  totalPrice: number;
  emailSend: boolean;
  promocode: IPromocode | null;
  requestSource: string;
  status: Status;
  userId: number;
  sessionId: string;
  items: Item[];
}

export interface Item {
  productId: number;
  product: Product;
  title: string;
  amount: number;
  price: number;
  createdAt: string;
  updatedAt: string;
}

export interface Product {
  id: number;
  title: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  course: Course;
}

export interface Course {
  id: number;
}

export interface Status {
  id: number;
  title: string;
}

export type AppOrSite = FilterSelectType['value'];

export type unionTimePeriodType =
  | 'all'
  | 'year'
  | 'month'
  | 'week'
  | 'day'
  | 'yesterday';

export const sortTypeStatisticsInit: Array<{
  label: string;
  value: number;
  secondLabel: string;
  type: unionTimePeriodType;
}> = [
  {
    label: 'За сегодня',
    secondLabel: 'Сегодня',
    value: 6,
    type: 'day',
  },
  {
    label: 'За вчера',
    secondLabel: 'Вчера',
    value: 5,
    type: 'yesterday',
  },
  {
    label: 'За неделю',
    value: 4,
    secondLabel: 'Неделя',
    type: 'week',
  },
  {
    label: 'За месяц',
    value: 3,
    secondLabel: 'Месяц',
    type: 'month',
  },
  {
    label: 'За год',
    value: 2,
    secondLabel: 'Год',
    type: 'year',
  },
  {
    label: 'За всё время',
    value: 1,
    secondLabel: 'Все время',
    type: 'all',
  },
];

export const requestSourceTypeStatisticsInit: Array<{
  label: string;
  value: 'mobile' | 'site';
}> = [
  {
    label: 'Приложение',
    value: 'mobile',
  },
  {
    label: 'Сайт',
    value: 'site',
  },
];
