import { addNewCategory } from '@/entities/categories/model/categoriesSlice';
import { useAppDispatch } from '@/shared/model';
import { ShowUntil } from '@/shared/ui/ShowUntil/ShowUntil';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

interface Form {
  title: string;
  image: FileList;
  showUntil: string;
}

export const AddCategotyModal: FC<Props> = ({
  modalVisible,
  setModalVisible,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>({ mode: 'all' });
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit(async (data) => {
    if (!data.image?.length) {
      enqueueSnackbar('Выберите изображение', { variant: 'warning' });
      return;
    }
    const formData = new FormData();
    formData.append('name', data.title);
    formData.append('image', data.image[0]);
    formData.append('showUntil', data.showUntil);
    setLoading(true);
    await dispatch(addNewCategory(formData));
    setLoading(false);
    reset();
    setModalVisible(false);
  });
  return (
    <Dialog
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      fullWidth
    >
      <DialogTitle>Добавить категорию</DialogTitle>
      <DialogContent>
        <Box component='form' onSubmit={onSubmit}>
          <TextField
            data-testid={'input-title'}
            label='Название категории'
            error={errors?.title !== undefined}
            margin='normal'
            {...register('title', {
              required: 'Введите название категории',
            })}
            fullWidth
            helperText={errors?.title?.message || ''}
          />
          <ShowUntil
            label='Показывать до'
            registerProps={register('showUntil')}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} />}
        <Button
          data-testid={'button-create'}
          variant='contained'
          type='submit'
          onClick={onSubmit}
        >
          Создать
        </Button>
        <Button
          data-testid={'button-cancel'}
          variant='contained'
          onClick={() => setModalVisible(false)}
          color='warning'
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
