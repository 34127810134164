import { InputHTMLAttributes } from 'react';

export const ToggleSwitch = ({
  label,
  ...props
}: { label?: string } & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div className={'flex select-none items-center justify-center gap-4'}>
      <label
        htmlFor={'check'}
        className='text-[18px] leading-[24px] text-black'
      >
        {label}
      </label>
      <label className='relative inline-flex cursor-pointer items-center gap-2'>
        <input
          data-testid={'toggle_switch'}
          className='peer sr-only'
          {...props}
          id='check'
          type={'checkbox'}
          checked={!!props.checked}
        />
        <div className="duration-250 peer-checked:hover:bg-blue-200 peer h-[32px] w-[60px] rounded-full bg-gray100 transition-all ease-in after:absolute after:left-[6px] after:top-[6px] after:h-[20px] after:w-[20px] after:rounded-full after:bg-white after:transition-all after:content-[''] hover:after:bg-gray200 peer-checked:bg-blue200 peer-checked:after:translate-x-[28px] peer-checked:after:bg-white peer-checked:hover:bg-blue300" />
      </label>
    </div>
  );
};
