import BaseScreen from 'src/widgets/BaseScreen';

import { TopBarUsers } from './components/TopBarUsers';

const Users = () => {
  return (
    <BaseScreen isSmallPT bg='white'>
      <TopBarUsers />
    </BaseScreen>
  );
};
export default Users;
