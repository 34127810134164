import { formatDateToDDMMYY } from '@/shared/lib/formatDate';
import { formatAuthType } from '@/shared/lib/formatEmail';
import sx from 'classnames';
import { memo } from 'react';

type Props = {
  id: any;
  type?: string;
  phone?: string;
  email?: string;
  authType?: string;
  createdAd?: Date;
  updatedAt?: Date;
  index?: number;
  length?: number;
};

const UserRegister = ({
  id,
  email,
  authType,
  createdAd,
  index,
  length,
}: Props) => {
  return (
    <div
      className={sx(
        'flex h-14 items-start justify-start gap-8 self-stretch rounded-[16px] border-[#F3F3F3] bg-[#FFF] px-6 py-4 text-lg font-normal not-italic leading-6 text-[color:var(--Simple-Night,#000)] transition-all duration-75 hover:cursor-pointer hover:bg-[#F3F3F3]',
        index === 0
          ? 'rounded-t-[16px] border-l border-r border-t'
          : 'rounded-[0px] border-l border-r border-t',
        index === length - 1 ? 'rounded-b-[16px] border-b' : 'rounded-[0px]'
      )}
    >
      <div className='w-[3.7%]'>{id && id}</div>
      <div className='col-span-1 w-[7.5%]'>
        {authType ? formatAuthType(authType) : '—'}
      </div>
      <div className='w-[77.3%]'>{email}</div>
      <div className='w-[11.5%]'>{formatDateToDDMMYY(createdAd)}</div>
    </div>
  );
};

export default memo(UserRegister);
