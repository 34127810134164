import { initSortValues } from '@/entities/reviews/types/reviews';
import sx from 'classnames';

interface Props {
  data: typeof initSortValues;
  activeSort?: any;
  ref?: any;
  handleClick: (
    event: React.MouseEvent<HTMLDivElement>,
    { label, value }: { label: string; value: number | string }
  ) => void;
}

export const SortValues = ({ data, handleClick, activeSort, ref }: Props) => {
  return (
    <div
      ref={ref}
      className='absolute right-0 top-[140%] flex h-min w-max flex-col justify-center gap-1 rounded-2xl bg-white p-[4px] shadow-lg'
    >
      {data.map(({ label, value }) => (
        <div
          data-testid={'button-select_sort'}
          onClick={(event) => handleClick(event, { label, value })}
          key={label}
          className={sx(
            'cursor-pointer rounded-2xl px-[16px] py-[13px] text-right text-base font-normal not-italic leading-[22px] hover:bg-[#F3F3F3]',
            label === activeSort?.label && 'bg-[#F3F3F3]'
          )}
        >
          {label}
        </div>
      ))}
    </div>
  );
};
