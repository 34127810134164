import { IFormCardProps } from '@/entities/marketplace/types/IFormCardProps';
import { TextArea } from '@/shared/ui/TextArea/TextArea';
import FormCard from '@/widgets/FormCard';
import { FC } from 'react';
interface Props extends IFormCardProps<any> {
  text: string;
}

export const SingleRecommendation: FC<Props> = ({
  onChange,
  onDelete,
  onSwap,
  text,
  id,
  index,
  itemsCount,
}) => {
  return (
    <FormCard
      index={index}
      itemsCount={itemsCount}
      key={id}
      onDelete={() => onDelete(id)}
      onSwap={onSwap}
    >
      <TextArea
        data-testid={`textarea-answer-${index}`}
        label='Описание'
        value={text}
        onChange={(e) =>
          onChange({
            value: e.target.value,
            index,
            key: 'text',
          })
        }
      />
    </FormCard>
  );
};
