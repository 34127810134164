import cn from 'classnames';
import {
  ChangeEvent,
  InputHTMLAttributes,
  RefObject,
  SVGProps,
  useRef,
  useState,
} from 'react';

import { Label } from '../Label/Label';

const ClearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.93934 0.93934C1.52513 0.353553 2.47487 0.353553 3.06066 0.93934L7 4.87868L10.9393 0.93934C11.5251 0.353553 12.4749 0.353553 13.0607 0.93934C13.6464 1.52513 13.6464 2.47487 13.0607 3.06066L9.12132 7L13.0607 10.9393C13.6464 11.5251 13.6464 12.4749 13.0607 13.0607C12.4749 13.6464 11.5251 13.6464 10.9393 13.0607L7 9.12132L3.06066 13.0607C2.47487 13.6464 1.52513 13.6464 0.93934 13.0607C0.353553 12.4749 0.353553 11.5251 0.93934 10.9393L4.87868 7L0.93934 3.06066C0.353553 2.47487 0.353553 1.52513 0.93934 0.93934Z'
      fill='currentColor'
    />
  </svg>
);
export const Input = ({
  containerClassName,
  label,
  required,
  withClear,
  onChange,
  innerRef,
  errorMessage,
  ...props
}: {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  containerClassName?: string;
  errorMessage?: string;
  innerRef?: RefObject<HTMLInputElement>;
  label?: string;
  withClear?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  const [defaultValue, setDefaultValue] = useState<string>('');
  const id = (Math.random() * Date.now()).toString(32).slice(0, 8);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(() => e.target.value);
  };
  return (
    <span
      className={cn('relative flex flex-col gap-[16px]', containerClassName)}
    >
      <Label label={label} required={required} id={props.id || id} />
      <div className={'relative flex h-full w-full'}>
        <input
          data-testid={'input'}
          {...props}
          ref={innerRef || defaultRef}
          value={props?.value || defaultValue}
          onChange={onChange ? onChange : handleChange}
          id={props.id || id}
          className={cn(
            'flex w-full gap-[16px] rounded-[16px] border-[1px] border-solid border-gray200 py-[23px] pl-[24px] pr-[48px] text-[20px] leading-[26px] placeholder:text-gray-300 focus:outline-0 active:outline-0 disabled:bg-gray100'
          )}
        />
        {withClear && (!!defaultValue || !!props?.value) && (
          <label
            onClick={() => {
              onChange &&
                onChange({
                  target: { value: '' },
                } as ChangeEvent<HTMLInputElement>);
              setDefaultValue(() => '');
              defaultRef?.current?.focus();
            }}
            className={
              'absolute right-[24px] top-1/2 -translate-y-1/2 cursor-pointer text-gray-300 hover:text-gray400'
            }
            htmlFor={props.id || id}
            data-testid={'button-clear'}
          >
            <ClearIcon />
          </label>
        )}
        <label
          onClick={() => defaultRef?.current?.focus()}
          htmlFor={id}
          className={'text-[16px] leading-[22px] text-red500'}
        >
          {!!errorMessage && errorMessage}
        </label>
      </div>
    </span>
  );
};
