import { ReactComponent as Calendar } from '@/assets/svg/Calendar.svg';
import { articlesActions } from '@/entities/articles/model/articleSlice';
import {
  addTopics,
  createSingleArticle,
} from '@/entities/articles/model/articleThunks';
import {
  EditArticleObject,
  PublicationStatus,
} from '@/entities/articles/types/IArticle';
import { sharedApi } from '@/shared/api/shared.api';
import { useCheckImagesSizes } from '@/shared/lib/hooks/useCheckImageSizes';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import * as UI from '@/shared/ui';
import DropInput from '@/shared/ui/DropInput/DropInput';
import { Editor } from '@/shared/ui/Editor/EditorExample';
import FormLabel from '@/shared/ui/FormLabel/FormLabel';
import { Input } from '@/shared/ui/Input/Input';
import NewButton from '@/shared/ui/NewButton/NewButton';
import { Select } from '@/shared/ui/Select';
import { ToggleSwitch } from '@/shared/ui/ToggleSwitch/ToggleSwitch';
import sx from 'classnames';
import React, { memo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { IsEditOrCreate } from '../index';
import { ImageUpload } from './ImageUpload';
import { PinArticle } from './PinArticle';
const Main = ({
  control,
  isValid,
  setValue,
  watch,
  handleSubmit,
  setIsImportant,
  isZakrep,
  status,
}: {
  setIsImportant?: any;
  isZakrep?: any;
  reset?: any;
  control?: Control<FieldValues, any>;
  isValid?: boolean;
  trigger?: any;
  setValue?: any;
  watch?: any;
  handleSubmit: any;
  status?: PublicationStatus;
  isCreatingOrEditing: IsEditOrCreate;
}) => {
  const [isPin, setIsPin] = useState(false);
  const topicOptions = useAppSelector((state) => state.articles.topicOptions);
  const formatOptions = useAppSelector((state) => state.articles.formatOptions);
  const currentFormat = useAppSelector(
    (state) => state.articles.articlesForm.main.format
  );
  const currentTopics = useAppSelector(
    (state) => state.articles.articlesForm.main.topics
  );

  const dispatch = useAppDispatch();
  const checkImages = useCheckImagesSizes();

  const onSubmitCreateArticle = async (data: any) => {
    if (status) return;
    let finalObject: EditArticleObject = {
      title: data?.title,
      body: data?.body,
      image: data?.image,
      authorId: null,
      publishedAt: data?.publishedAt,
      superOrder: isZakrep ? 1 : 0,
      formatId: formatOptions.find((item) => item?.id === data.format.id)?.id,
    };
    const image =
      typeof data.image === 'string'
        ? data.image
        : await (async () => {
            const fd = new FormData();
            fd.append('file', data.image);
            const ans = await sharedApi.createFile(fd);
            return ans.data.file;
          })();
    finalObject = {
      ...finalObject,
      image: image,
    };

    try {
      const res = await dispatch(
        createSingleArticle({ formData: finalObject })
      );

      const id = res.payload.id;
      await dispatch(
        addTopics({
          articleId: Number(id),
          data: { ids: data.topics.map((item: any) => item.id) },
        })
      );
      window.open(`/articles/article/edit-article/${res.payload.id}`, '_self');
    } catch (error) {
      dispatch(
        setNotification({ value: 'Ошибка при создании', variant: 'error' })
      );
    }
  };

  const onClear = (field: string) => {
    const inputElement = document.querySelector(
      `input[name=${field}]`
    ) as HTMLInputElement;
    setValue(field, '', { shouldDirty: true });
    inputElement.focus();
  };

  return (
    <UI.Block>
      <PinArticle
        opened={isPin}
        setOpened={setIsPin}
        onPin={() => {
          dispatch(articlesActions.setFormDirty(true));
          setIsImportant(true);
        }}
      />
      <fieldset className='mb-[40px] flex w-[100%] items-center justify-between'>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Описание
        </h1>
        <ToggleSwitch
          data-testid={'toggle-pin-article'}
          checked={isZakrep}
          label={'Закрепить'}
          onChange={() => (isZakrep ? setIsImportant(false) : setIsPin(true))}
        />
      </fieldset>
      <form
        className='flex w-[100%] flex-col gap-[32px]'
        onSubmit={handleSubmit(onSubmitCreateArticle)}
      >
        <Controller
          name='publishedAt'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <div className='relative flex w-[428px] flex-col items-start gap-[16px]'>
              <FormLabel
                htmlFor={'publishedAt'}
                title='Дата публикации'
                required
              />
              <ReactDatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={field?.onChange}
                dateFormat='dd.MM.yyyy'
                className={sx(
                  'flex h-[72px] w-[428px] cursor-pointer items-center gap-4 self-stretch truncate px-6 py-[23px] pr-[42px] text-xl font-normal not-italic leading-[26px] outline-none',
                  'rounded-2xl border border-solid border-[color:var(--Simple-Gray-Day,#E1E1E1)]'
                )}
                onBlur={field?.onBlur}
              />
              <span className='absolute right-[24px] top-[57%]'>
                <Calendar />
              </span>
            </div>
          )}
        />
        <div className='flex gap-[32px]'>
          <Controller
            name='topics'
            control={control}
            rules={{ required: true }}
            render={() => (
              <Select
                data-testid={'select-topics'}
                name={'topics'}
                id={'topics'}
                selected={currentTopics || []}
                required
                isSingle={false}
                values={topicOptions}
                handleSelect={(newValue) => {
                  const selected = (currentTopics || []).find(
                    (v: any) => newValue.id === v.id
                  );
                  dispatch(articlesActions.setFormDirty(true));
                  if (selected) {
                    const newSelected = (currentTopics || [])
                      .filter((v: any) => newValue.id !== v.id)
                      // @ts-ignore
                      .map(({ id, title, value, label }) => ({
                        id: id || value,
                        title: title || label,
                      }));
                    dispatch(articlesActions.setCurrentTopics(newSelected));
                    setValue('topics', newSelected, { shouldDirty: true });
                    return;
                  }
                  const newSelected = [...(currentTopics || []), newValue];
                  dispatch(articlesActions.setCurrentTopics(newSelected));
                  setValue('topics', newSelected, { shouldDirty: true });
                }}
                label={'Темы'}
                placeholderClassName={
                  currentTopics?.length ? '' : 'text-gray-300'
                }
                containerClassName={' flex-1'}
                placeholder={
                  currentTopics
                    ?.map((v: any) => v?.title || 'Название не указано')
                    .join(', ') || 'Выберите'
                }
              />
            )}
          />

          <Controller
            name='format'
            control={control}
            rules={{ required: true }}
            // defaultValue={currentFormat?.title || ''}
            render={({ field: { onChange, value } }) => (
              <Select
                name={'formats'}
                data-testid={'select-formats'}
                id={'formats'}
                selected={value || currentFormat}
                required
                values={formatOptions}
                handleSelect={(value) => {
                  dispatch(articlesActions.setCurrentFormat(value));
                  onChange(value);
                  setValue('formats', value, { shouldDirty: true });
                }}
                label={'Формат'}
                containerClassName={' flex-1'}
                placeholderClassName={
                  currentFormat?.title ? '' : 'text-gray-300'
                }
                placeholder={currentFormat?.title || 'Выберите'}
              />
            )}
          />
        </div>
        <Controller
          name='image'
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <DropInput
              setFiles={async ([files]: any) => {
                const err = await checkImages({
                  files: files,
                  minHeight: 720,
                  minWidth: 1280,
                });
                if (err) {
                  dispatch(
                    setNotification({
                      variant: 'error',
                      value: 'Ошибка при загрузке (маленький размер)',
                    })
                  );
                  return;
                }
                dispatch(articlesActions.setCoverFileInitDirty());
                onChange({ target: { value: files[0] } });
              }}
              withIcon
              sizes={'(1280x720 px)'}
              onDelete={() => onClear('image')}
              files={value ? [value] : []}
              label='image'
              htmlFor={'image'}
              format='dropzone'
              title={'Обложка'}
              setValue={setValue}
              required
              name='image'
              id='image'
            />
          )}
        />
        <Controller
          name='title'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              {...field}
              type='type'
              htmlFor={'title'}
              title={'Заголовок'}
              required
              isClose={watch('title')?.length > 0}
              onClear={() => onClear('title')}
            />
          )}
        />
        <ImageUpload />
        <Controller
          name='body'
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Editor
              {...field}
              htmlFor='description'
              title='Описание'
              required
            />
          )}
        />
        {!status?.id && (
          <div className='flex items-center justify-center'>
            <NewButton
              variant='submit'
              type='submit'
              disabled={!isValid}
              onClick={onSubmitCreateArticle}
              styles='w-[219px] max-h-[56px]'
            >
              Сохранить
            </NewButton>
          </div>
        )}
      </form>
    </UI.Block>
  );
};

export default memo(Main);
