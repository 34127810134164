import * as React from 'react';

const SvgComponent = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='32' height='32' rx='16' fill='#F3F3F3' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.93934 9.93934C10.5251 9.35355 11.4749 9.35355 12.0607 9.93934L16 13.8787L19.9393 9.93934C20.5251 9.35355 21.4749 9.35355 22.0607 9.93934C22.6464 10.5251 22.6464 11.4749 22.0607 12.0607L18.1213 16L22.0607 19.9393C22.6464 20.5251 22.6464 21.4749 22.0607 22.0607C21.4749 22.6464 20.5251 22.6464 19.9393 22.0607L16 18.1213L12.0607 22.0607C11.4749 22.6464 10.5251 22.6464 9.93934 22.0607C9.35355 21.4749 9.35355 20.5251 9.93934 19.9393L13.8787 16L9.93934 12.0607C9.35355 11.4749 9.35355 10.5251 9.93934 9.93934Z'
      fill='#ABABAB'
    />
  </svg>
);

export default SvgComponent;
