import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { TextArea } from '@/shared/ui/TextArea/TextArea';
import React from 'react';
export const DescriptionInput = () => {
  const dispatch = useAppDispatch();
  const description = useAppSelector(
    (state) => state.marketplace.courseForm.form.description
  );

  return (
    <TextArea
      label={'Описание'}
      name={'description'}
      id={'description'}
      data-testid={'textarea-description'}
      value={description}
      length={description?.length}
      required
      title='Описание'
      onChange={(v) => {
        dispatch(
          marketplaceActions.changeCoursesForm({
            key: 'description',
            value: v.target.value,
          })
        );
      }}
    />
  );
};
