import { IFormat } from '@/entities/articles/types/IArticle';
import sx from 'classnames';
import React, { FC, InputHTMLAttributes } from 'react';
import ArrowDown from 'src/assets/svg/arrowDown';
import CloseSearch from 'src/assets/svg/closeSearch';

import FormLabel from '../FormLabel/FormLabel';

import './Input.css';
export interface FileWithPreview extends File {
  preview: string;
}
export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  htmlFor: string;
  isClose?: boolean;
  setValue?: any;
  title: string;
  currentValue?: string;
  isOpen?: boolean;
  options?: IFormat[];
  currentOption?: IFormat;
  noLabel?: boolean;
  icon?: React.ReactNode;
  onClear?: () => void;
  action?: React.ReactNode;
  setIsOpened?: () => void;
  onSelected?: (type: string, option: IFormat) => void;
  format?: 'select' | 'input';
}

export const Input: FC<Props> = ({
  error = '',
  className = '',
  htmlFor,
  currentValue,
  isOpen,
  title,
  icon,
  noLabel,
  options,
  currentOption,
  action,
  isClose,
  setValue,
  onClear,
  onSelected,
  format = 'input',
  required,
  setIsOpened,
  ...props
}) => {
  return (
    <>
      {format === 'input' && (
        <span className='relative flex w-full flex-col items-start gap-[16px]'>
          {!noLabel && (
            <FormLabel
              htmlFor={htmlFor}
              title={title}
              required={required}
              action={action}
            />
          )}
          <div className={'relative flex h-full w-full'}>
            <input
              data-testid='input'
              type='text'
              className={sx(
                'flex h-[72px] w-full items-center gap-4 self-stretch truncate px-6 py-[23px] pr-[42px] text-xl font-normal not-italic leading-[26px] outline-none',
                'rounded-2xl border border-solid border-[#E1E1E1]',
                { 'border-red': error },
                className
              )}
              {...props}
            />
            {isClose && (
              <span
                className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer'
                onClick={onClear}
              >
                <CloseSearch />
              </span>
            )}
            {icon && (
              <span className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer'>
                {icon}
              </span>
            )}
          </div>
        </span>
      )}
      {format === 'select' && (
        <>
          <span
            className={sx('relative z-10 flex flex-col gap-[16px]', className)}
          >
            <FormLabel
              htmlFor={htmlFor}
              title={title}
              required={required}
              className='self-start'
            />
            <input
              {...props}
              onClick={(event) => {
                event.stopPropagation();
                setIsOpened();
              }}
              className={sx(
                'z-0 flex h-[72px] cursor-pointer items-center gap-4 self-stretch truncate px-6 py-[23px] text-xl outline-none',
                'rounded-2xl border border-solid border-[color:var(--Simple-Gray-Day,#E1E1E1)]',
                { 'border-red': error },
                'select-input',
                className
              )}
              readOnly
            />
            <button
              className={`btn-select absolute right-[24px] top-[60%] transition ${
                isOpen ? 'rotate-180' : ''
              }`}
            >
              <ArrowDown />
            </button>
            {isOpen && (
              <div
                className={sx(
                  'absolute right-0 top-[50%] z-10 mb-[4px] flex w-[100%] flex-col items-start gap-1 rounded-2xl bg-white p-1 shadow-lg'
                )}
              >
                {options?.map((option, index) => (
                  <div
                    key={option?.id || index}
                    className={sx(
                      'w-[100%] cursor-pointer rounded-2xl px-[16px] py-[13px] text-left text-base font-normal not-italic leading-[22px]',
                      currentValue === option?.title
                        ? 'active-option'
                        : 'hover:bg-[#F3F3F3]'
                    )}
                    onClick={() => {
                      onSelected(htmlFor, option);
                    }}
                  >
                    {option?.title || ''}
                  </div>
                ))}
              </div>
            )}
          </span>
        </>
      )}

      {error && <p className='text-sm text-red'>{error}</p>}
    </>
  );
};
