import BaseScreen from 'src/widgets/BaseScreen';

import { TopBarPurchases } from './components/TopBarPurchases';

const Purchases = () => {
  return (
    <BaseScreen bg='white'>
      <TopBarPurchases />
    </BaseScreen>
  );
};
export default Purchases;
