import { fetchAllEmailUsers } from '@/entities/statistics/model/statisticsThunks';
import { useIsScrolled } from '@/shared/lib/hooks/useIsScrolled';
import { usePaginate } from '@/shared/lib/hooks/usePaginate';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import * as UI from '@/shared/ui';
import GoBack from '@/shared/ui/GoBack/GoBack';
import React, { useEffect } from 'react';

import StatsHead from '../../components/StatsHead';
import Skeletons from '../../Sceletons/Skeletons';
import UserRegister from './UserRegister';

export const TopBarUsers = () => {
  const userRegister = useAppSelector((state) => state.statistics.emailUsers);
  const dateSort = useAppSelector((state) => state.statistics.dateSort);
  const dispatch = useAppDispatch();

  const dateType = dateSort ? 'ASC' : 'DESC';

  useEffect(() => {
    dispatch(fetchAllEmailUsers({ createdAt: dateType }));
  }, [dateSort]);

  const isLoading = useAppSelector((state) => state.statistics.loading);
  const isScrolled = useIsScrolled(150);
  const { ref, page } = usePaginate(
    () =>
      dispatch(
        fetchAllEmailUsers({ createdAt: dateType, limit: 40, page: page })
      ),
    {
      initialPage: 1,
      deps: [dateSort],
      data: userRegister,
    }
  );
  return (
    <div className='container'>
      <GoBack />
      <div className='mb-[32px] flex h-[56px] items-center justify-between'>
        <div className='flex h-full items-center justify-center gap-8'>
          <UI.SectionTitle title='Регистрации' />
        </div>
      </div>
      <StatsHead type='registrations' isScrolled={isScrolled} />
      {isLoading && <Skeletons type={3} />}
      {userRegister?.map((item, index) => (
        <UserRegister
          index={index}
          length={userRegister?.length}
          id={item?.userId || item?.id}
          authType={item?.authType}
          createdAd={item?.createdAt}
          updatedAt={item?.updatedAt}
          email={item?.email}
          key={`${item?.userId || item?.id}${item?.createdAt}`}
        />
      ))}
      {userRegister?.length !== 0 && <div ref={ref}></div>}
    </div>
  );
};

export default TopBarUsers;
