import { fetchAllCourses } from '@/entities/marketplace/model/markeplaceThunks';
import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { promocodesActions } from '@/entities/promocodes/model/promocodesSlice';
import { createOrEditPromocode } from '@/entities/promocodes/model/promocodesThunks';
import { statisticsActions } from '@/entities/statistics/model/statisticsSlice';
import { fetchAllUsers } from '@/entities/statistics/model/statisticsThunks';
import { routes } from '@/shared/consts/consts';
import { generateRandomString } from '@/shared/lib/generateRandomString';
import { useEscapeKey } from '@/shared/lib/hooks/useEscapeKey';
import { usePaginate } from '@/shared/lib/hooks/usePaginate';
import { useScrollToBottom } from '@/shared/lib/hooks/useScrollDown';
import { normalizeData } from '@/shared/lib/normalizer';
import { normilizeProducts } from '@/shared/lib/normilizeProducts';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import * as UI from '@/shared/ui';
import { DateInput } from '@/shared/ui/DatePicker/DatePicker';
import { DropdownInput } from '@/shared/ui/Dropdown/Dropdown';
import { Input } from '@/shared/ui/Input/Input';
import NewButton from '@/shared/ui/NewButton/NewButton';
import { Select } from '@/shared/ui/Select/Select';
import { useDebounce } from '@uidotdev/usehooks';
import { addYears } from 'date-fns';
import React, { ChangeEvent } from 'react';
import './Calendar.css';
import { Outlet, useBlocker, useNavigate, useParams } from 'react-router-dom';

import DiscountType from '../components/DiscountType';
import GeneratePromo from '../components/GeneratePromo';
import { ContinueModal } from './ContinueFormModal';

const PromocodeForm = () => {
  const [openMaterial, setIsOpenMaterial] = React.useState(false);
  const [openAccess, setIsOpenAccess] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const promocodeTypes = useAppSelector(
    (state) => state.promocodes.types.types
  );
  const externalAccessOptions = useAppSelector(
    (state) => state.promocodes.types.accessTypes
  );
  const { courses: products } = useAppSelector((state) => state.marketplace);
  const { users, search: usersSearch } = useAppSelector(
    (state) => state.statistics
  );
  const {
    form: formState,
    isFormSucceed: formSuccess,
    types,
    promocodes: { promocodes },
  } = useAppSelector((state) => state.promocodes);
  const marketplaceState = useAppSelector((state) => state.marketplace);

  const debouncedSearch = useDebounce(usersSearch, 300);
  const debouncedCoursesSearch = useDebounce(
    marketplaceState?.['courseFilters'].search,
    300
  );
  const isEdit = !!id;

  const { ref, page } = usePaginate(
    () =>
      dispatch(
        fetchAllUsers({
          lastActivityAt: 'DESC',
          search: debouncedSearch,
          limit: 40,
          page: page,
        })
      ),
    {
      initialPage: 1,
      deps: [debouncedSearch],
    }
  );

  const currentPromocode = promocodes?.find((p) => p.id === Number(id));
  React.useLayoutEffect(() => {
    dispatch(
      marketplaceActions.changeFilters({
        type: 'courseFilters',
        key: 'status',
        value: 3,
      })
    );
  }, []);
  React.useEffect(() => {
    if (!currentPromocode && isEdit) {
      navigate(`/${routes.adminRoutes.promocodes}`);
    }
  }, []);

  React.useEffect(() => {
    dispatch(fetchAllCourses());
  }, [debouncedCoursesSearch]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(statisticsActions.onSearch(e.target.value));
  };

  const handleGeneratePromocode = () => {
    dispatch(
      promocodesActions.changeFormField({
        field: 'title',
        value: generateRandomString(6),
      })
    );
  };

  const handleAddProductsPromocode = (id?: number) => {
    dispatch(
      promocodesActions.changeFormField({
        field: 'productsId',
        value: formState.productsId?.includes(id)
          ? formState.productsId.filter((item) => item !== id)
          : [...formState.productsId, id],
      })
    );
  };

  const handleAddUsersPromocode = (id?: number) => {
    dispatch(
      promocodesActions.changeFormField({
        field: 'usersId',
        value: formState.usersId?.includes(id)
          ? formState.usersId.filter((item) => item !== id)
          : [...formState.usersId, id],
      })
    );
    dispatch(
      promocodesActions.changeFormField({
        field: 'accessTypeId',
        value: 3,
      })
    );
  };

  const handleSetAccessType = (id?: number) => {
    dispatch(
      promocodesActions.changeFormField({
        field: 'accessTypeId',
        value: id,
      })
    );
    dispatch(
      promocodesActions.changeFormField({
        field: 'usersId',
        value: [],
      })
    );
    setIsOpenAccess(false);
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !formSuccess && currentLocation.pathname !== nextLocation.pathname
  );

  const handleSubmitCreatePromocode = async ({
    isEdit,
    id,
  }: {
    isEdit?: boolean;
    id?: number;
  }) => {
    if (
      promocodes.some((promo) => promo.title === formState.title) &&
      !isEdit
    ) {
      dispatch(
        setNotification({
          value: 'Ошибка, такой промокод уже существует',
          variant: 'error',
        })
      );
      return;
    }
    try {
      await dispatch(
        createOrEditPromocode({ formData: formState, isEdit, id })
      );
      window.scrollTo(0, 0);
      dispatch(
        setNotification({
          value: `Промокод ${isEdit ? 'изменен' : 'добавлен'}`,
          variant: 'success',
        })
      );
      setTimeout(() => {
        navigate(0);
      }, 300);
      blocker?.reset?.();
      navigate('/promocodes');
    } catch (e) {
      dispatch(
        setNotification({
          value: `Ошибка при ${
            isEdit ? 'редактировании' : 'добавлении'
          } промокода, ${e}`,
          variant: 'error',
        })
      );
    }
  };
  // Очищение формы
  React.useEffect(() => {
    return () => {
      dispatch(promocodesActions.clearPromocodeForm());
      dispatch(
        marketplaceActions.changeFilters({
          type: 'courseFilters',
          key: 'search',
          value: '',
        })
      );
      dispatch(statisticsActions.onSearch(''));
    };
  }, []);

  React.useEffect(() => {
    if (formState.usersId?.length === 0 && formState.accessTypeId === 3) {
      dispatch(
        promocodesActions.changeFormField({
          field: 'accessTypeId',
          value: 1,
        })
      );
    }
  }, [formState.usersId]);

  React.useEffect(() => {
    if (
      formState.discount > 100 &&
      formState.discountCurrency === 'percentage'
    ) {
      dispatch(
        promocodesActions.changeFormField({
          field: 'discount',
          value: 100,
        })
      );
    }
  }, [formState.discount, formState.discountCurrency]);

  const disabled = !formState?.title || !formState.discount;
  const blockStyle =
    'mt-[32px] flex w-[434px] flex-col items-stretch gap-[32px]';

  useScrollToBottom(openAccess, document?.getElementById('modal'));
  useEscapeKey(() => navigate(`/${routes.adminRoutes.promocodes}`));

  return (
    <>
      {blocker && blocker?.state === 'blocked' && (
        <ContinueModal
          opened
          setOpened={blocker?.reset}
          handleContine={blocker?.reset}
          handleExit={() => {
            blocker?.proceed();
            navigate(`/${routes.adminRoutes.promocodes}`);
          }}
        />
      )}
      <UI.Modal
        className='flex flex-col items-center gap-[0px]'
        opened={blocker?.state !== 'blocked'}
        onClose={() => {
          navigate(`/${routes.adminRoutes.promocodes}`);
        }}
      >
        <h1 className='text-center text-[32px] font-medium leading-10 tracking-normal'>
          {isEdit ? 'Обновить' : 'Добавить'} промокод
        </h1>
        <div className='flex w-[100%] items-center justify-center gap-[42px]'>
          <div className={blockStyle}>
            <Input
              autoFocus
              data-testid={'input-promo'}
              title='Промокод'
              label='promocode'
              htmlFor='promocode'
              required
              // maxLength={12}
              onChange={(e) =>
                dispatch(
                  promocodesActions.changeFormField({
                    field: 'title',
                    value: e.target.value.toUpperCase(),
                  })
                )
              }
              value={formState?.title || ''}
              className='self-center'
              action={<GeneratePromo onGenerate={handleGeneratePromocode} />}
            />
            <Input
              data-testid={'input-discount'}
              type='numeric'
              title='Скидка'
              label='sale'
              htmlFor='sale'
              value={formState?.discount || ''}
              onChange={(e) => {
                dispatch(
                  promocodesActions.changeFormField({
                    field: 'discount',
                    value: Number(e.target.value),
                  })
                );
              }}
              required
              icon={
                <DiscountType
                  isActiveType={
                    formState.discountCurrency as 'ruble' | 'percentage'
                  }
                  setActive={(value: 'ruble' | 'percentage') => {
                    dispatch(
                      promocodesActions.changeFormField({
                        field: 'discountCurrency',
                        value,
                      })
                    );
                  }}
                />
              }
            />
            <DateInput
              initValue={formState?.endDate as Date}
              onClear={() => {
                dispatch(
                  promocodesActions.changeFormField({
                    field: 'endDate',
                    value: null,
                  })
                );
              }}
              value={formState?.endDate as unknown as string}
              placeholder='Бессрочно'
              onChange={(value) => {
                dispatch(
                  promocodesActions.changeFormField({
                    field: 'endDate',
                    value: value,
                  })
                );
              }}
              maxDate={addYears(new Date(Date.now()), 100)}
              label={'Дата завершения'}
              data-testid={'input-date-end'}
            />
          </div>
          <div className={blockStyle}>
            <Select
              name={'select-type-promocode'}
              data-testid={'select-type-promocode'}
              id={'type-promocode'}
              isSingle
              selected={types.types?.find(
                (item) => item.id === formState.typeId
              )}
              required={false}
              values={promocodeTypes}
              handleSelect={(value) => {
                dispatch(
                  promocodesActions.changeFormField({
                    field: 'typeId',
                    value: Number(value.id),
                  })
                );
              }}
              label={'Вид промокода'}
              containerClassName={'w-[100%] flex-1'}
              placeholder={
                types.types?.find((item) => item.id === formState.typeId)?.title
              }
            />
            <DropdownInput
              isOpened={openMaterial}
              title='Материал'
              isUpside
              search={marketplaceState?.['courseFilters'].search}
              opened={openMaterial}
              singleName={
                formState?.productsId?.length === 1 &&
                products?.find(
                  (item) => item.product.id === formState?.productsId[0]
                )?.product?.title
              }
              onClearOptions={() => {
                dispatch(
                  promocodesActions.changeFormField({
                    field: 'productsId',
                    value: [],
                  })
                );
                setIsOpenMaterial(false);
              }}
              onChoose={(id) => handleAddProductsPromocode(id)}
              onClose={() => setIsOpenMaterial(false)}
              setIsOpened={setIsOpenMaterial}
              onChange={(e) =>
                dispatch(
                  marketplaceActions.changeFilters({
                    type: 'courseFilters',
                    key: 'search',
                    value: e.target.value,
                  })
                )
              }
              defaultOption='Любой'
              currentOptions={formState?.productsId}
              options={normalizeData(
                normilizeProducts(products),
                'id',
                'title'
              )}
            />
            <DropdownInput
              isOpened={openAccess}
              title='Доступ'
              isUpside
              innerRef={ref}
              onChange={handleSearch}
              search={usersSearch}
              onClose={() => setIsOpenAccess(false)}
              opened={openAccess}
              singleName={
                (formState?.usersId?.length === 1 ||
                  currentPromocode?.users?.length === 1) &&
                (currentPromocode?.users?.[0]?.email ||
                  users?.find((item) => item.id === formState?.usersId?.[0])
                    ?.email)
              }
              currentOptions={formState?.usersId}
              onChoose={(id) => handleAddUsersPromocode(id)}
              activeExternal={formState?.accessTypeId}
              onChooseExternal={(id) => handleSetAccessType(id)}
              setIsOpened={setIsOpenAccess}
              defaultOption={
                types.accessTypes[formState?.accessTypeId - 1]?.title
              }
              onClearOptions={() => {
                dispatch(
                  promocodesActions.changeFormField({
                    field: 'usersId',
                    value: [],
                  })
                );
                dispatch(
                  promocodesActions.changeFormField({
                    field: 'accessTypeId',
                    value: 1,
                  })
                );
              }}
              options={normalizeData(users, 'id', 'email', 'phone')}
              externalOptions={normalizeData(
                externalAccessOptions?.slice(0, -1),
                'id',
                'title'
              )}
            />
          </div>
        </div>
        <NewButton
          variant={'primary'}
          disabled={disabled}
          styles='w-[360px] mt-[32px]'
          onClick={() => handleSubmitCreatePromocode({ isEdit, id: +id })}
        >
          {isEdit ? 'Обновить' : 'Добавить'}
        </NewButton>
      </UI.Modal>
      <Outlet />
    </>
  );
};

export default PromocodeForm;
