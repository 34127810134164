import { ReactComponent as Plus } from '@/assets/svg/Plus.svg';
import {
  changePublicationType,
  deleteArticle,
  fetchAllArticles,
} from '@/entities/articles/model/articleThunks';
import { Areas } from '@/pages/Articles/ui/Areas';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import * as UI from '@/shared/ui';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import ArticleCard from '@/widgets/EditEntityCard';
import { Template } from '@/widgets/Template';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Loading from '../Review/views/Loading/Loading';

const Index = () => {
  const {
    articles,
    articlesLoading: loading,
    filter,
    sort,
    searchValue: search,
  } = useAppSelector((state) => state.articles);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      fetchAllArticles({
        search: search,
        sort: sort.value as 'ASC' | 'DESC',
        publicationStatusId: filter?.value,
      })
    );
  }, [search, sort, filter?.value]);
  const navigate = useNavigate();
  const handleSubmit =
    (id: number) => async (to: 'delete' | 'hide' | 'publish') => {
      if (to === 'delete') {
        await dispatch(deleteArticle({ id: Number(id) }));
        return;
      }
      await dispatch(
        changePublicationType({
          articleId: id,
          type: to,
        })
      );
    };
  return (
    <div className='container pt-12'>
      <div className='mb-10'>
        <div className='container'>
          <div className='align-center mb-12 flex h-[56px] justify-between'>
            <div className='flex h-full items-center gap-4'>
              <UI.SectionTitle title='Статьи' />
              <div className={'relative'}>
                <Tooltip tooltipClassName='top-[-72px]' text='Добавить статью'>
                  <div
                    className='200ms flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[50%] bg-[#337CEC] transition hover:bg-[#1B56B1]'
                    onClick={() => navigate('article/create-article')}
                  >
                    <Plus />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <Areas />
          <Outlet />
        </div>
        {loading && <Loading />}
        {!!articles && (
          <Template>
            {articles?.map((elem) => (
              <ArticleCard
                handleSubmit={handleSubmit(elem.id)}
                actionTitle={'статью'}
                isPinned={elem.superOrder > 0}
                publishedAt={String(elem.publishedAt)}
                status={elem.publicationStatus.id}
                title={elem.title}
                editLink={`/articles/article/edit-article/${elem.id}`}
                key={elem.id}
              />
            ))}
          </Template>
        )}{' '}
      </div>
      <Outlet />
    </div>
  );
};

export default Index;
