import { statisticsActions } from '@/entities/statistics/model/statisticsSlice';
import { fetchAllBoughtProducts } from '@/entities/statistics/model/statisticsThunks';
import Search from '@/pages/Review/components/SearchInput';
import useFilterVisibility from '@/shared/lib/hooks/useFilterVisibility';
import { useIsScrolled } from '@/shared/lib/hooks/useIsScrolled';
import { usePaginate } from '@/shared/lib/hooks/usePaginate';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import * as UI from '@/shared/ui';
import ActiveFilters from '@/shared/ui/ActiveFilters/ActiveFilters';
import { FilterSelectType } from '@/shared/ui/Filters';
import GoBack from '@/shared/ui/GoBack/GoBack';
import { useDebounce } from '@uidotdev/usehooks';
import classNames from 'classnames';
import React, { ChangeEvent, useEffect } from 'react';

import StatsHead from '../../components/StatsHead';
import Skeletons from '../../Sceletons/Skeletons';
import { Filters } from '../../views/Areas';
import Purchase from './Purchase';

export const TopBarPurchases = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.statistics.activeReqSource);
  const dateSort = useAppSelector((state) => state.statistics.dateSort);
  const purchases = useAppSelector((state) => state.statistics.products);
  const isLoading = useAppSelector((state) => state.statistics.loading);
  const search = useAppSelector((state) => state.statistics.search);
  const debouncedSearchTerm = useDebounce(search, 300);

  const handleRemoveFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    v: FilterSelectType['value']
  ) => {
    dispatch(statisticsActions.removeFilter({ type: v }));
  };

  const handleRemoveAllFilters = () => {
    dispatch(statisticsActions.removeAllFilter());
  };

  const dateType = dateSort ? 'ASC' : 'DESC';

  useEffect(() => {
    return () => {
      dispatch(statisticsActions.clearSearch());
    };
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(statisticsActions.onSearch(e.target.value));
  };

  const isScrolled = useIsScrolled();

  const {
    isFilterVisible,
    filterSelectOptions,
    handleMouseEnter,
    handleMouseLeave,
  } = useFilterVisibility();
  const { ref, page } = usePaginate(
    () =>
      dispatch(
        fetchAllBoughtProducts({
          search: debouncedSearchTerm,
          filters: [filters.map((item) => item.value)],
          createdAt: dateType,
          limit: 40,
          page: page,
        })
      ),
    {
      initialPage: 1,
      deps: [debouncedSearchTerm, filters, dateSort],
      data: purchases,
    }
  );

  return (
    <div className='container'>
      <GoBack />
      <div className='mb-[32px] flex h-[56px] items-center justify-between'>
        <div className='flex h-full items-center justify-center gap-8'>
          <UI.SectionTitle title='Покупки' />
        </div>
      </div>
      <div className='mb-[24px] flex flex-col gap-[24px]'>
        {' '}
        <div className='flex gap-[4px]'>
          <Filters
            isPurchases
            isFilterVisible={isFilterVisible}
            filterSelectOptions={filterSelectOptions}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
          <div className='flex-1'>
            <Search
              onChange={handleSearch}
              value={search}
              isReview
              onClear={() => dispatch(statisticsActions.onSearch(''))}
              isLetter={search.length > 0}
            />
          </div>
        </div>
        {filters?.length ? (
          <div className={classNames('flex gap-1')}>
            <ActiveFilters
              isPurchases
              activeReqs={filters}
              handleRemoveAllFiltersReqs={handleRemoveAllFilters}
              handleRemoveFilterReqs={handleRemoveFilter}
            />
          </div>
        ) : null}
      </div>
      <StatsHead type='purchases' isScrolled={isScrolled} />
      {isLoading && (
        <div className='mt-[15px]'>
          <Skeletons type={1} />
        </div>
      )}
      <div className='mt-[15px]'>
        {purchases?.map((item, index) => (
          <Purchase
            key={item?.id}
            index={index}
            length={purchases?.length}
            id={item?.items[0]?.productId}
            company={item?.items[0]?.product?.title}
            phone={item?.phone}
            price={item?.totalPrice}
            promocode={item?.promocode}
            email={item?.email}
            isSuccess={item.status.id === 2}
            date={item?.items[0]?.createdAt}
          />
        ))}
      </div>
      {purchases?.length !== 0 && <div ref={ref}></div>}
    </div>
  );
};
