import { IFormPromocode } from '@/entities/promocodes/types/IPromocodeForm';
import { IPromocodes, Type } from '@/entities/promocodes/types/IPromocodes';
import { paginate } from '@/shared/lib/hooks/usePaginate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createOrEditPromocode,
  deletePromocode,
  getAllPromocodes,
  getAllPromocodesAccessTypes,
  getAllPromocodesTypes,
} from './promocodesThunks';

export type IFilter =
  | {
      usageCounter: 'ASC' | 'DESC';
      endDate: 'ASC' | 'DESC';
    }
  | Record<string, any>;

interface IPromocodeState {
  promocodes: IPromocodes;
  loading: {
    list: boolean;
    form: boolean;
  };
  filters: IFilter;
  isFormSucceed: boolean;
  search: string;
  form: IFormPromocode | null;
  types: {
    accessTypes: Type[];
    types: Type[];
  };
}

const initialState = {
  promocodes: {},
  loading: {
    list: false,
    form: false,
  },
  form: {
    title: '',
    endDate: null,
    discountCurrency: 'percentage',
    discount: null,
    productsId: [],
    usersId: [],
    accessTypeId: 1,
    typeId: 1,
  },
  isFormSucceed: false,
  filters: {
    usageCounter: null,
    endDate: null,
  },
  search: '',
  types: {
    accessTypes: [],
    types: [],
  },
} as IPromocodeState;

type Sum<T> = T[keyof T];
type Mapped<T> = Sum<{
  [K in keyof T]: { field: K; value: T[K] };
}>;

const promocodesSlice = createSlice({
  name: 'promocodes',
  initialState,
  reducers: {
    setPromocodeForm(state, action: PayloadAction<IFormPromocode>) {
      state.form = action.payload;
    },
    changeFormField(state, action: PayloadAction<Mapped<IFormPromocode>>) {
      const { field, value } = action.payload;
      //@ts-ignore
      state.form[field] = value;
    },
    clearPromocodeForm(state) {
      state.form = { ...initialState.form };
      state.isFormSucceed = false;
    },
    changeSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    changeFilter(state, action: PayloadAction<{ key: keyof IFilter }>) {
      const { key } = action.payload;
      state.filters[key] = state.filters[key] === null ? 'DESC' : null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllPromocodes.fulfilled, (state, action) => {
      state.promocodes = {
        promocodes: paginate({
          page: action.payload.page,
          data: action.payload.data.promocodes,
          state: state.promocodes.promocodes,
        }),
      };
      state.loading.list = false;
    });

    builder.addCase(getAllPromocodesTypes.fulfilled, (state, action) => {
      state.types.types = action.payload.types;
    });
    builder.addCase(getAllPromocodesAccessTypes.fulfilled, (state, action) => {
      state.types.accessTypes = action.payload.accessTypes;
    });
    builder.addCase(createOrEditPromocode.fulfilled, (state) => {
      state.isFormSucceed = true;
    });
    builder.addCase(deletePromocode.fulfilled, (state, action) => {
      state.promocodes.promocodes = state.promocodes.promocodes.filter(
        (item) => item.id !== action.meta.arg.id
      );
    });
    builder.addCase(getAllPromocodes.pending, (state) => {
      state.loading.list = true;
    });
  },
});

export const { actions: promocodesActions, reducer: promocodesReducer } =
  promocodesSlice;
