import { useArticle } from '@/pages/Articles/model/use-article';
import { ChangeDateModal } from '@/pages/Articles/ui/Description/ChangeDateModal';
import { useAppSelector } from '@/shared/model';
import FormTabs from '@/shared/ui/FormTabs/FormTabs';
import FormWrapper from '@/shared/ui/FormWrapper/FormWrapper';
import NewButton from '@/shared/ui/NewButton/NewButton';
import BaseScreen from '@/widgets/BaseScreen';
import Header from '@/widgets/Header/Header';
import { SaveChangesModal } from '@/widgets/SaveChangesModal/SaveChangesModal';
import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Outlet, useBlocker } from 'react-router-dom';

import { tabs } from '../consts';

export interface IsEditOrCreate {
  id: number | null;
  authorId: number | null;
  edit: boolean;
}

const CreateArticleFormCard = () => {
  const [exitScreen] = useState(false);
  const formDirty = useAppSelector((state) => state.articles.formDirty);
  const superOrder = useAppSelector(
    (state) => state.articles.articlesForm.main.superOrder
  );
  const activeTab = useAppSelector((state) => state.articles.activeTab);
  const status = useAppSelector(
    (state) => state.articles.articlesForm.main.publicationStatus
  );

  const loading = useAppSelector(
    (state) => state.articles.articlesForm.isLoading
  );

  const articleForm = useAppSelector((state) => state.articles.articlesForm);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    setFocus,
    formState: { errors, dirtyFields },
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      title: articleForm.main.title || '',
      publishedAt: articleForm.main.publishedAt || Date.now(),
      body: articleForm.main.body || '',
      topics: articleForm.main?.topics,
      format: articleForm.main.format,
      image: articleForm.main?.image,
      avatar: articleForm.author?.avatar || [],
      name: articleForm.author?.name || '',
      positionAtWork: articleForm.author?.positionAtWork || '',
    },
  });

  const {
    handleFinalSubmitEditArticleForm,
    isZakrep,
    setIsImportant,
    changeDate,
    setChangeDate,
    handleChangeTab,
    isCreatingOrEditing,
  } = useArticle(watch);
  useEffect(() => {
    if (loading === false) {
      reset(
        {
          title: articleForm.main.title || '',
          publishedAt: articleForm.main.publishedAt || Date.now(),
          body: articleForm.main.body || '',
          topics: articleForm.main?.topics,
          format: articleForm.main.format,
          image: articleForm.main?.image,
          avatar: articleForm.author?.avatar || [],
          name: articleForm.author?.name || '',
          positionAtWork: articleForm.author?.positionAtWork || '',
        },

        { keepDirty: false }
      );
    }
  }, [loading]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      (Object.keys(dirtyFields).length > 0 ||
        formDirty !== false ||
        (superOrder > 0 && isZakrep === false) ||
        (superOrder === 0 && isZakrep === true)) &&
      currentLocation.pathname !== nextLocation.pathname
  );
  const isValid = !Object.keys(errors)?.length;

  const Component = tabs?.find((tab) => tab?.value === activeTab)?.tab;

  return (
    <BaseScreen exitScreen={exitScreen}>
      <Header />
      {blocker && blocker?.state === 'blocked' && (
        <SaveChangesModal
          opened={blocker?.state === 'blocked'}
          redirectUrl={blocker?.location.pathname}
          setOpened={blocker?.reset}
          status={status}
          handleSubmit={handleSubmit}
          isValid={isValid}
          handleFinalSubmitForm={handleFinalSubmitEditArticleForm}
        />
      )}
      <ChangeDateModal
        opened={changeDate}
        control={control}
        setOpened={setChangeDate}
        status={status}
        handleSubmit={handleSubmit}
        isValid={isValid}
        handleFinalSubmitForm={handleFinalSubmitEditArticleForm}
      />

      <FormWrapper>
        <FormTabs
          onClick={handleChangeTab}
          active={activeTab}
          backLink={'/articles'}
          tabs={
            isCreatingOrEditing.edit
              ? tabs
              : tabs.map((tab, index) =>
                  index !== 0 ? { ...tab, disabled: true } : tab
                )
          }
          isValid={isValid}
          setChangeDate={setChangeDate}
          handleSubmit={handleSubmit}
        >
          {status?.id === 3 && isCreatingOrEditing.edit && (
            <form
              onSubmit={handleSubmit((data: any) =>
                handleFinalSubmitEditArticleForm(data, false)
              )}
            >
              <NewButton
                data-testid={'button-update_article'}
                variant='primary'
                type='submit'
                styles={'mt-[16px]'}
                disabled={
                  !isValid ||
                  (!Object.values(dirtyFields)?.length && !formDirty)
                }
              >
                Обновить
              </NewButton>
            </form>
          )}
          {(status?.id === 1 || status?.id === 2) && (
            <NewButton
              data-testid={'button-modal-publish_article'}
              variant='primary'
              type='submit'
              styles={'mt-[16px]'}
              onClick={() => setChangeDate && setChangeDate(true)}
              disabled={!isValid}
            >
              Опубликовать
            </NewButton>
          )}
          {(status?.id === 1 || status?.id === 2) && (
            <form
              onSubmit={handleSubmit((data: any) =>
                handleFinalSubmitEditArticleForm(data, false)
              )}
            >
              <NewButton
                data-testid={'button-save_article'}
                variant='submit'
                type='submit'
                styles={'mt-[16px]'}
                disabled={
                  !isValid ||
                  (!Object.values(dirtyFields)?.length && !formDirty)
                }
                onClick={handleFinalSubmitEditArticleForm}
              >
                Сохранить
              </NewButton>
            </form>
          )}
        </FormTabs>

        <Component
          control={control}
          isValid={isValid}
          isZakrep={isZakrep}
          setFocus={setFocus}
          status={status}
          setIsImportant={setIsImportant}
          setValue={setValue}
          reset={reset}
          watch={watch}
          handleSubmit={handleSubmit}
          isCreatingOrEditing={isCreatingOrEditing}
        />
      </FormWrapper>
      <Outlet />
    </BaseScreen>
  );
};

export default memo(CreateArticleFormCard);
