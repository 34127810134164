import { formatDateToDDMMYY } from '@/shared/lib/formatDate';
import { formatAuthType } from '@/shared/lib/formatEmail';
import { formatPhoneNumber } from '@/shared/lib/formatPhone';
import sx from 'classnames';
import { memo } from 'react';

const UserActive = ({
  id,
  email,
  authType,
  phone,
  createdAt,
  index,
  length,
  lastActivityAt,
}: any) => {
  return (
    <div
      className={sx(
        'flex h-14 items-start justify-start gap-8 self-stretch rounded-[16px] border-[#F3F3F3] bg-[#FFF] px-6 py-4 text-lg font-normal not-italic leading-6 text-[color:var(--Simple-Night,#000)] transition-all duration-75 hover:cursor-pointer hover:bg-[#F3F3F3]',
        index === 0
          ? 'rounded-t-[16px] border-l border-r border-t'
          : 'rounded-[0px] border-l border-r border-t',
        index === length - 1 ? 'rounded-b-[16px] border-b' : 'rounded-[0px]'
      )}
    >
      <div className='col-span-1 w-[3.7%]'>{id}</div>
      <div className='col-span-1 w-[7.5%]'>
        {authType ? formatAuthType(authType) : '—'}
      </div>
      <div className='col-span-1 w-[12.18%]'>
        {phone ? formatPhoneNumber(phone) : '—'}
      </div>
      <div className='col-span-1 w-[54.62%]'>{email ? email : '—'}</div>
      <div className='col-span-1 w-[11.5%]'>
        {formatDateToDDMMYY(createdAt)}
      </div>
      <div className='col-span-1 w-[10.5%]'>
        {formatDateToDDMMYY(lastActivityAt)}
      </div>
    </div>
  );
};

export default memo(UserActive);
