import { editCategory } from '@/entities/categories/model/categoriesSlice';
import { ICategory } from '@/entities/categories/types/ICategory';
import { setState } from '@/shared/interfaces/common/setState';
import { toDateInputValue } from '@/shared/lib/toDateInputValue';
import { useAppDispatch } from '@/shared/model';
import { ShowUntil } from '@/shared/ui/ShowUntil/ShowUntil';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  modalVisible: boolean;
  setModalVisible: setState<boolean>;
  category: ICategory;
}

interface Form {
  title: string;
  image?: FileList;
  showUntil: string;
}

export const EditCategoryModal: FC<Props> = ({
  modalVisible,
  setModalVisible,
  category,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>({
    mode: 'all',
    values: {
      title: category.name,
      showUntil: toDateInputValue(new Date(category.showUntil)),
    },
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append('name', data.title);
    formData.append('image', data.image[0] || category.image);
    formData.append('showUntil', data.showUntil);
    setLoading(true);
    await dispatch(editCategory({ id: category.id, formData }));
    setLoading(false);
    reset();
    setModalVisible(false);
  });
  return (
    <Dialog
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      fullWidth
    >
      <DialogTitle>Редактировать категорию</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          onSubmit={onSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
        >
          <TextField
            data-testid={`input-title-${category.id}`}
            label='Название категории'
            error={errors?.title !== undefined}
            margin='normal'
            {...register('title', {
              required: 'Введите название категории',
            })}
            fullWidth
            helperText={errors?.title?.message || ''}
          />
          <ShowUntil
            label='Показывать до'
            registerProps={register('showUntil')}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} />}
        <Button
          data-testid={`button-edit-${category.id}`}
          variant='contained'
          type='submit'
          onClick={onSubmit}
        >
          Редактировать
        </Button>
        <Button
          data-testid={'button-cancel'}
          variant='contained'
          onClick={() => setModalVisible(false)}
          color='warning'
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
