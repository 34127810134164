import Search from '@/pages/Review/components/SearchInput';
import { useEscapeKey } from '@/shared/lib/hooks/useEscapeKey';
import { useOutsideClick } from '@/shared/lib/hooks/useOutsideClick';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import { Label } from '../Label/Label';
import { ArrowIcon, MultipleSelectProps, SelectProps } from './model';

export const SelectMultiple = ({
  values,
  label,
  containerClassName,
  placeholder,
  placeholderClassName,
  required,
  selected,
  handleSelect,
  ...props
}: MultipleSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onSelect = (value: SelectProps) => {
    handleSelect(value);
  };
  const ref = useOutsideClick(() => setIsOpen(false));
  useEscapeKey(() => setIsOpen(false));
  const isSelected = (value: SelectProps) => {
    return (
      value?.id ===
      selected?.find((v) => v?.id?.toString() === value?.id?.toString())?.id
    );
  };
  const isDisabled = props.disabled || !values?.length;
  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    setIsOpen((v) => !v);
  };

  const [searchValue, setSearchValue] = useState('');
  const [foundOptions, setFoundOptions] = useState<SelectProps[]>([]);

  useEffect(() => {
    if (!searchValue) {
      setFoundOptions(values);
      return;
    }
    const regex = new RegExp(searchValue, 'gi');
    const filteredOptions = values?.filter((option) =>
      regex.test(option.title.toString())
    );
    setFoundOptions(filteredOptions);
  }, [searchValue, values]);

  const selectedOptions = useMemo(() => {
    return values?.filter((value) => isSelected(value));
  }, [values, selected]);
  return (
    <div
      ref={ref}
      className={cn('flex flex-col gap-[16px]', containerClassName)}
    >
      {selected.map((v, i) => (
        <input key={i} {...props} type={'hidden'} value={v?.id} />
      ))}
      <Label onClick={handleClick} label={label} required={required} />
      <div
        className={`relative ${
          isDisabled ? 'cursor-disabled' : 'cursor-pointer'
        }`}
      >
        <button
          className={cn(
            `flex w-full max-w-full text-start ${
              isDisabled && 'cursor-disabled bg-gray100'
            } gap-[16px] overflow-hidden overflow-ellipsis rounded-[16px] border-[1px] border-solid border-gray200 py-[23px] pl-[24px] pr-[48px] text-[20px] leading-[26px]`,
            placeholderClassName
          )}
          data-testid={'button-toggle_select'}
          onClick={handleClick}
        >
          {placeholder}
        </button>
        <div
          onClick={handleClick}
          data-testid={'button_arrow-toggle'}
          className={`absolute right-[22px] top-1/2 z-0 -translate-y-1/2 select-none transition-all duration-150 ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          <ArrowIcon />
        </div>
        <div
          className={`absolute z-10 w-full pt-[6px] ${!isOpen ? 'hidden' : ''}`}
        >
          <div
            className={
              'z-1 flex max-h-[600px] w-full flex-col gap-[16px]  rounded-[16px] bg-white p-[8px] shadow-[0_10px_40px_-15px_rgba(0,0,0,0.3)]'
            }
          >
            <div className={'flex w-full flex-col gap-2'}>
              <Search
                data-testid={'search'}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                isReview
                className={'text-[20px] leading-[26px]'}
                isLetter={searchValue.length > 0}
                onClear={() => setSearchValue('')}
              />
              <ul
                className={
                  'flex max-h-[300px] w-full flex-col gap-1 overflow-y-auto'
                }
              >
                {!!values?.length &&
                  foundOptions?.map((value) => (
                    <li
                      data-testid={`button-select-${value.id}`}
                      onClick={() => onSelect(value)}
                      key={value.id}
                      className={`flex h-full w-full flex-1 cursor-pointer gap-3 rounded-[16px] px-[16px] py-[15px] text-start text-[20px] leading-[26px] hover:bg-gray100 ${
                        isSelected(value) && 'bg-blue-100 hover:bg-blue100'
                      }`}
                    >
                      <Checkbox
                        onClick={() => onSelect(value)}
                        isOpen={isSelected(value)}
                      />
                      {value?.title || (
                        <div className={'text-red500'}>Название не указано</div>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
            {!!selectedOptions?.length && (
              <>
                <div className={'w-full px-4'}>
                  <hr
                    className={
                      'flex h-[1px] w-full items-center justify-center bg-gray100 text-gray100'
                    }
                  />
                </div>
                <div
                  className={
                    'flex max-h-[250px] w-full flex-col gap-[4px] overflow-y-auto'
                  }
                >
                  {selectedOptions?.map((value) => (
                    <button
                      type={'button'}
                      key={value.id}
                      onClick={() => onSelect(value)}
                      className={
                        'flex h-full w-full flex-1 cursor-pointer gap-[12px] rounded-[16px] px-[16px] py-[15px] text-start text-[20px] leading-[26px] hover:bg-gray100'
                      }
                    >
                      {value?.title || (
                        <div className={'text-red500'}>Название не указано</div>
                      )}
                    </button>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
