import reviewsService from '@/entities/reviews/api/reviews-service';
import { IReview, IRevParams } from '@/entities/reviews/types/reviews';
import { tryCatch } from '@/shared/lib/tryCatch';
import { setNotification } from '@/shared/model/appSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAllReviews = createAsyncThunk(
  'reviews/getReviews',
  async (
    { limit, offset, filters, createdAd, rate, search }: IRevParams,
    { rejectWithValue }
  ) => {
    try {
      const [data, error] = await tryCatch<{ ratings: IReview[] }>(
        reviewsService.getAllReviews(
          limit,
          offset,
          filters,
          createdAd,
          rate,
          search
        )
      );

      if (error) {
        throw rejectWithValue(
          `Ошибка в получении отзывов (${error.response.status})`
        );
      }

      return data.ratings;
    } catch (err) {
      throw rejectWithValue(`Ошибка в получении отзывов: ${err.message}`);
    }
  }
);

export const publicateReview = createAsyncThunk(
  'reviews/publicateReview',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [, error] = await tryCatch<{ reviews: IReview[] }>(
      reviewsService.publicateReview(id)
    );
    if (error) {
      dispatch(
        setNotification({
          value: `Что-то пошло не так [${error.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue({ message: error.message });
    }
  }
);

export const deleteReview = createAsyncThunk(
  'reviews/deleteReview',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [, error] = await tryCatch<{ reviews: IReview[] }>(
      reviewsService.deleteReview(id)
    );
    if (error) {
      dispatch(
        setNotification({
          value: `Что-то пошло не так [${error.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue({ message: error.message });
    }
  }
);

export const sendOnModerationReview = createAsyncThunk(
  'reviews/sendOnModerationReview',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [, error] = await tryCatch<{ reviews: IReview[] }>(
      reviewsService.sendOnModerationReview(id)
    );
    if (error) {
      dispatch(
        setNotification({
          value: `Что-то пошло не так [${error.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue({ message: error.message });
    }
  }
);
