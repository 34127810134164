/* eslint-disable react-hooks/exhaustive-deps */
import sx from 'classnames';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import GoBack from '../GoBack/GoBack';

interface Props<T> {
  tabs: { label: ReactNode; value: T; logo?: any; disabled?: boolean }[];
  active: string;
  styles?: string;
  onClick: (value: T) => void;
  isValid?: boolean;
  handleSubmit?: any;
  setChangeDate?: any;
  handleFinalSubmitForm?: any;
  backLink: string;
  children?: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const FormTabs = <T,>({
  tabs,
  active,
  styles,
  onClick,
  backLink,
  children,
}: Props<T>) => {
  const navigate = useNavigate();
  return (
    <aside>
      <div className='sticky left-0 top-[168px] flex w-[280px] flex-col'>
        <div className='absolute top-[-48px]'>
          <GoBack onClick={() => navigate(backLink)} />
        </div>
        <div
          className={sx(
            'gap sticky flex w-[280px] flex-col rounded-[32px] bg-[#FFF] p-[12px]',
            styles
          )}
        >
          {tabs.map((elem) => (
            <button
              key={elem.value.toString() || elem.label.toString()}
              disabled={elem?.disabled}
              className={sx(
                'flex items-center gap-3 self-stretch truncate text-nowrap rounded-[16px] border-none py-[15px] pl-4 pr-6 text-xl font-normal not-italic leading-[26px] outline-none transition disabled:opacity-50',
                elem.value === active
                  ? 'bg-[#EBF1FC] text-black'
                  : 'border-transparent text-black'
              )}
              data-testid={'button-tab-' + elem.value.toString()}
              onClick={() => onClick(elem.value)}
            >
              {elem?.logo && elem?.logo}
              {elem.label}
            </button>
          ))}
        </div>
        {children}
      </div>
    </aside>
  );
};

export default FormTabs;
