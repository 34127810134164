import { fetchAllStatistics } from '@/entities/statistics/model/statisticsThunks';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import DeleteModal from '../Review/sub-pages/views/DeleteModal/DeleteModal';
import { TopBar } from './views/TopBar';

const Statistics = () => {
  const isShown = useAppSelector((state) => state.reviews.isDeleteModal);
  const activeSort = useAppSelector((state) => state.statistics.sort);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllStatistics(activeSort.type));
  }, [activeSort]);
  return (
    <div className='pt-12'>
      <div className='container mb-10 ml-auto mr-auto overflow-visible'>
        <TopBar />
      </div>
      <Outlet />
      {isShown.isShown && <DeleteModal />}
    </div>
  );
};
export default Statistics;
